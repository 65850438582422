.field-name-field-accordion-items-order-form {
  .node-product-order-form {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    padding: 30px 0;
    align-items: center;
    border-bottom: 1px solid #d1d1d1;
    .field-name-field-order-description {
      max-width: 800px;
      width: 80%;
      padding-right: 50px;
      @media (max-width: 991px) {
        width: 50%;
        padding-right: 30px;
      }
      @media (max-width: 767px) {
        width: 100%;
        padding-right: 0;
      }
    }

    .field-name-field-order-products {
      width: 20%;
      @media (max-width: 991px) {
        width: 50%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      text-align: center;
      button[type=submit] {
        width: 180px;
        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
        box-shadow: 0 5px 14px rgba(33, 33, 33, .5);
        font-size: 14px;
        transition: all 300ms;
        margin: 5px 0 !important;
        &:hover {
          background: #439243;
          transform: scale(1.1,1.1);
        }
        div {
          font-size: 16px;
          margin-top: 6px;
          padding-top: 6px;
          border-top: 1px solid #FFF;
          font-weight: bold;
        }
      }
      button[disabled] {
        color: green !important;
        border-color: green !important;
        background: #FFF !important;
        &:before {
          display: block;
          content: "✔";
          color: green;
        }
        &:hover, &:focus {
          color: green;
          border-color: green;
          background: #FFF;
          box-shadow: none;
          transform: none;
        }
        &.circle-is-full {
          &:before {
            display: none;
          }
          color: #333 !important;
          border-color: #333 !important;
          &:hover, &:focus {
            color: #333 !important;
            border-color: #333 !important;
          }
        }
      }
    }

    .order-full-description {
      display: inline-block;

      .summary-modal {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: left;
        background: rgba(0, 0, 0, .75);
        transition: opacity .5s ease;
        z-index: 9999;
      }

      .summary-modal__bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
      }

      .summary-modal-state {
        display: none;
      }

      .summary-modal-state:checked + .summary-modal {
        opacity: 1;
        visibility: visible;
      }

      .summary-modal-state:checked + .summary-modal .summary-modal__inner {
        top: 0;
      }

      .summary-modal__inner {
        position: absolute;
        top: -20%;
        right: 0;
        bottom: 0;
        left: 0;
        width: 80%;
        margin: auto;
        overflow: auto;
        background: #fff;
        border-radius: 5px;
        padding: 1em 2em;
        height: 60%;
      }

      .summary-modal__close {
        position: absolute;
        right: 1em;
        top: 1em;
        width: 1.1em;
        height: 1.1em;
        cursor: pointer;
      }

      .summary-modal__close:after,
      .summary-modal__close:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 1.5em;
        background: #ccc;
        display: block;
        transform: rotate(45deg);
        left: 50%;
        margin: -3px 0 0 -1px;
        top: 0;
      }

      .summary-modal__close:hover:after,
      .summary-modal__close:hover:before {
        background: #aaa;
      }

      .summary-modal__close:before {
        transform: rotate(-45deg);
      }

      @media screen and (max-width: 768px) {
        .summary-modal__inner {
          width: 90%;
          height: 90%;
          box-sizing: border-box;
        }
      }

      .summary-btn {
        cursor: pointer;
        display: inline-block;
        color: #3498db;
      }

      .summary-btn:hover,
      .summary-btn:focus,
      .summary-btn:active {
        text-decoration: underline;
      }
    }
  }

  .field-item:first-child .node-product-order-form {
    padding-top: 0;
  }

  .field-item:last-child .node-product-order-form {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

body.dashboard .panel-pane.pane-custom-guild-dashboard.green-pane {
  padding-bottom: 7px;
}

#mini-panel-guild_circles_dashboard {
  margin-bottom: 15px;
}

.panel-pane.pane-guild-circle-information {
  margin-bottom: 0 !important;
  > div {
    > div {
      padding: 20px;
    }
    .view-display-id-block_roster,
    .view-display-id-block_roster_special {
      margin-left: -15px;
      margin-right: -15px;
    }
    .field-dates {
      margin-bottom: 22px;
    }
    label {
      font-size: 18px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 4px;
      margin-bottom: 10px;
    }
    .views-row {
      padding: 10px 0;
      text-align: center;
      .views-field-field-user-image {
        margin-bottom: 10px;
        img {
          border-radius: 50%;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
        }
      }
      .views-field-name {
        margin-bottom: 5px;
      }
      .views-field-mail {
        margin-top: 8px;
      }
    }
  }
}


