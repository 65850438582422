.page-diocese-individual-learner-data-report {
  table {
    table-layout: fixed;
    word-break: break-word;
  }

  .page-admin-lmsreports .panels-ajax-tab {
    font-size: 16px;
  }

  .pane-panels-ajax-tab-tabs {
    margin: 20px 0 30px;
    text-align: center;
  }

  ul.panels-ajax-tab li:not(:last-child) {
    margin-right: 10px;
  }

  ul.panels-ajax-tab li {
    padding: 0.8rem;
    background-color: #337ab7;
    list-style: none;
    display: inline-block;
  }

  ul.panels-ajax-tab li a {
    color: white;
  }

  #edit-diocese-wrapper {
    display: none;
  }
}

.page-diocese-cumulative-data-report {
  tr.odd td.views-field-uid {
    background-color: #fdfdfa;
  }

  .pane-cumulative-report-tracks tr.even td.views-field-uid, .pane-cumulative-report-courses tr.even td.views-field-uid {
    background-color: #fdfdfa !important;
  }

  .pane-cumulative-report-tracks tr.odd td.views-field-uid, .pane-cumulative-report-courses tr.odd td.views-field-uid {
    background-color: #f9f9f9;
  }

  td.views-field-view-1, td.views-field-view {
    width: 120px;
  }

  .pane-cumulative-report-tracks .pane-title, .pane-cumulative-report-courses .pane-title {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h2.pane-title {
    font-weight: bold;
    font-size: 30px;
  }

  .view-display-id-multiple_tracks .view-content {
    display: none;
  }

  .view-display-id-multiple_tracks .view-footer {
    padding: 8px 10px;
    margin-bottom: 10px;
    font-size: 16px;
    background-color: #fdfdfa;
  }

  td.views-field-uid {
    font-size: 16px;
  }

  .pane-cumulative-data-report-multiple-tracks {
    margin-top: 50px;
    border-top: 2px solid lightgrey;
    padding-top: 30px;
  }
}
