.field-name-field-accordion-items-fpp {
  .field-item {
    margin-top: 12px;
    h3.pane-title {
      cursor: pointer;
      font-size: 20px;
      padding-left: 22px;
      position: relative;
      span.glyphicon {
        position: absolute;
        left: -4px;
        top: 0;
      }
      &[aria-expanded=true] span.glyphicon {
        transform:rotate(90deg);
      }
    }
    .panel-body {
      max-width: 850px;
    }
    li {
      margin-bottom: 18px;
    }
  }
}

h3.pane-title {

}
