// Site Name: Franciscan At Home
html #branding .pane-content h1 {
  transition: opacity 1s ease-out;
  transition: margin 0.85s ease-out;
  opacity: 0;
  margin-left: -100px;
}

html.wf-active #branding .pane-content h1 {
  opacity: 1;
  margin-left: 0;
}

// Site Slogan
html #branding div.slogan {
  transition: opacity 1s ease-out;
  transition: margin 0.85s ease-out;
  opacity: 0;
  margin-right: -100px;
}

html.wf-active #branding div.slogan {
  opacity: 1;
  margin-right: 0;
}

// Workshop Title

.node-type-workshop h1#page-title {
  transition: opacity 0.7s ease-out;
  opacity: 0;
}

.wf-active .node-type-workshop h1#page-title {
  opacity: 1;
}

// Workshop Description

html .node-type-workshop .pane-node-field-workshop-description {
  transition: opacity 1.2s ease-out;
  opacity: 0;
}

html.wf-active .node-type-workshop .pane-node-field-workshop-description {
  opacity: 1;
}
