// This file contains styling to make Drupal & Bootstrap play more nicely.
// ----------------------------------------------------------------------------

// Containers within containers.
.container .container,
.container .container-fluid,
.container-fluid .container,
.container-fluid .container-fluid,
.container-parent .container,
.container-parent .container-fluid {
  width: auto;
  margin-left: -15px;
  margin-right: -15px;
}

// Make all responsive images inline-block (so they can be centered), with an
// auto height (so they don't appear stretched thanks to inline styles).
.img-responsive {
  display: inline-block !important;
  height: auto !important;
}

// Add circle, rounded, and thumbnail support if class is in parent element.
.img-circle img {
  .img-circle();
}
.img-rounded img {
  .img-rounded();
}
.img-thumbnail img {
  .img-thumbnail();
}

// Fix images in tables.
table .img-responsive {
  vertical-align: initial;
  max-width: initial;
}

// Fix tablesort images.
table th.active .img-responsive {
  margin-left: 4px;
}

// Prevent buttons with long text from breaking layouts.
.btn {
  white-space: normal;
}

// Add space between button icons and text.
.btn .glyphicon:first-child {
  margin-right: 5px;
}

// Remove right/left border radius for button groups within input groups.
.input-group .btn-group:not(.last-child) .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group .btn-group:not(.first-child) .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

// Fixes issues with autocomplete fields in a responsive table. Just set the
// overflow for mobile only.
.table-responsive {
  overflow-x: initial;
  @media @mobile-only {
    overflow-x: auto;
  }
}

// Remove default top border on table cells.
.table {
  > thead, > tbody, > tfoot {
    > tr {
      > th, > td {
        border-top: none;
      }
    }
  }
}

// Remove left padding from first table cell, unless it's a bordered or striped
// table.
.table:not(.table-bordered):not(.table-striped) {
  th:first-child, td:first-child {
    padding-left: 0;
  }
}

// Remove top margin from modal form actions (undoing what's in misc.less).
.modal-footer .form-actions {
  margin-top: 0;
}

// Fixes the scrolling overlay issue.
.modal-content {
  padding-bottom: 1px;
}

// Reset Bootstrap page header component.
.page-header {
  padding-bottom: 0;
  margin: 0;
  border-bottom: none;
}

// Use smaller left padding for nested lists.
.list-unstyled ul, .list-unstyled ol {
  padding-left: 1em;
}

// Reverse blockquotes.
.blockquote-reverse, blockquote.pull-right {
  padding: 0 45px 0 15px;
  border-right: none;
  text-align: right;

  &:after {
    content: '\201D';
    right: 0;
  }
}

// Make panel descriptions a normal font size.
.panel-body > .help-block {
  font-size: 1em;
}

// Add spacing between pager controls.
.pager-current {
  margin-left: 10px;
  margin-right: 10px;
}
