// Margin below customer profile on checkout form.
#customer-profile-billing-ajax-wrapper {
  margin-bottom: @line-height-computed;
}

// Margin beneath payment method selection radios.
#edit-commerce-payment-payment-method {
  margin-bottom: @form-group-margin-bottom;
}

// Commerce credit card expiration.
.commerce-credit-card-expiration {
  select {
    width: inherit;
    display: inline-block;
  }
  .form-item {
    display: inline-block;
    margin-bottom: 0;
  }
}
