body.page-institution-sign-up {
  .form-item-institution-contact-contact-title {
      .make-sm-column(3);
      .make-md-column(2);
      padding-left: 0;
  }
  .form-item-institution-contact-contact-name {
      .make-sm-column(9);
      .make-md-column(10);
      padding-right: 0;
  }
  .form-item-institution-contact-contact-ministry-title {
    clear: both;
  }
}
