@media only screen and (min-width: 1200px) {
  .login-form-wrapper {
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: lightgrey;
    padding-right: 30px;
  }
  .new-account-wrapper {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .login-form-wrapper {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: lightgrey;
    padding-bottom: 30px;
    .new-account-wrapper {
      padding-left: 30px;
    }
  }
}

.login-page-wrapper {
  display: flex;
  flex-flow: wrap;
  justify-content: stretch;
  .login-form-wrapper {
    #edit-actions {
      display: inline;
      float: left;
    }
  }
  .request-new-password {
    display: inline;
    float: left;
    margin-top: 22px;
    margin-left: 30px;
  }
  .new-account-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      color: white;
      background-color: #5cb85c;
      border-color: #4cae4c;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      padding: 6px 12px;
      border-radius: 4px;
      user-select: none;
      text-decoration: none;
    }
    a:hover {
      background-color: #439243;
    }
  }
}