// Margin and padding helper classes.
.margin-padding(@class, @sizes) {
  .-(@i: length(@sizes)) when (@i > 0) {
    @size: extract(@sizes, @i);
    .margin-@{class}-top-@{size} {margin-top: ~"@{size}px"};
    .margin-@{class}-bottom-@{size} {margin-bottom: ~"@{size}px"};
    .padding-@{class}-top-@{size} {padding-top: ~"@{size}px"};
    .padding-@{class}-bottom-@{size} {padding-bottom: ~"@{size}px"};
    .-((@i - 1));
  } .-;
}

// Light Bottom Border.
.light-bottom-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 4px;
}


// Descrive Text.
.description {
  font: 300 18px/20px @sans;
  color: #A2A2A2;
  text-transform: uppercase;
  margin-bottom: 5px;
}

// Callout Bubble Thing.
.callout {
  position: relative;
	border: 1px solid rgba(0, 0, 0, 0.2);
  background: #FFF;
  border-radius: 4px;
  padding: 20px;
  &:after, &:before {
  	bottom: 100%;
  	border: solid transparent;
  	content: " ";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
  }
  &:before {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid rgba(0, 0, 0, 0.35);
    left: 24px;

  }
  &:after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #FFF;
    left: 25px;
  }
}

// Realistic shadow.
.paper(@one: 0.1; @two: 0.3) {
  box-shadow:0 1px 4px rgba(0, 0, 0, @one), 0 0 20px rgba(0, 0, 0, @two) inset;
}

// Color helper classes.
.colors(@colors) {
	.-(@i: length(@colors)) when (@i > 0) {
		@color: extract(@colors, @i);
        .text-@{color} {color: ~"@{color-@{color}}"};
        .bg-@{color} {background-color: ~"@{color-@{color}}"};
        .link-@{color}, .links-@{color} a {color: ~"@{color-@{color}}"};
        .border-@{color} {border-color: ~"@{color-@{color}}"};
        .-((@i - 1));
    } .-;
}

// Give element opacity when hovering over it. Uses backface-visibility to
// remove flicker (see http://stackoverflow.com/a/13020881).
.opacity-hover(@hover-opacity: .5; @initial-opacity: 1) {
  & when not (@initial-opacity = @hover-opacity) {
    opacity: @initial-opacity;
  }
  transition: opacity 300ms;
  backface-visibility: hidden;
  &:hover {
    opacity: @hover-opacity;
  }
}

// Responsive iframe. Uses technique to create padding bottom in the DIV
// wrapper equal to the ratio of the height to the width. Set the width/height
// directly on the iframe, wrap in in a DIV, and then add this mixin to that
// DIV's class. Works well for Mobile Safari, which sometimes won't respect an
// iframe with 100% width.
//
// See http://alistapart.com/article/creating-intrinsic-ratios-for-video
// See http://stackoverflow.com/a/12678294
.responsive-iframe(@xs-height: 100%; @sm-height: 65%; @md-height: 65%; @lg-height: 65%) {
  position: relative;
  height: 0;
  overflow: hidden;

  padding-bottom: @xs-height;
  @media @tablet {
    padding-bottom: @sm-height;
  }
  @media @desktop {
    padding-bottom: @md-height;
  }
  @media @wide {
    padding-bottom: @lg-height;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Green title for panes
.green-pane-title {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  margin: -20px -20px 20px;
  padding: 14px 22px;
  color: #FFF;
  background:#3A6646;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  border-radius: 3px 3px 0 0;
}

// Green subtitle for panes
.green-pane-subtitle {
  margin-bottom: 0;
  color: #FFF;
  background-color: #669472;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

//
.green-light-bg {
  background: rgba(109, 156, 121, 0.1);
}
