/* Electives for a Track */

.view-track-elective-workshops {
  .view-content {
    .make-row();
  }
  .item-list {
    .make-md-column(4);
  }
  h3 {
    font: italic 20px/24px @sans;
  }
  .views-row {
    margin-bottom: 5px;
  }
}
