.mooc-page:not(.dashboard) {
  &.no-title .pane-page-title {
    display: none;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: @font-family-serif;
  }

  h2:not(#footer h2) {
    color: @gold;
  }

  .flex-body {
    h3,
    h3 a {
      color: @green;
    }

    .btn {
      width: fit-content;
      margin: 15px auto 0;
    }
  }

  .panel-pane.pane-custom {
    margin-bottom: 30px;

    .panel-body {
      padding-top: 0;

      .panel-body {
        padding-top: 12px;
      }
    }
  }
}

.view-mooc-sidebar.view-display-id-block_advertisement {
  .views-row {
    width: 65%;
    margin: 15px auto;
  }

  img.img-responsive {
    width: 100%;
  }
}