// Purchase Institution Membership
#institution-purchase-membership {margin-top: 25px;}

// Coupon Code Link
#coupon-link {
  text-align: left;
  margin-bottom: 12px;
  a {
    transition: all 300ms;
    display: inline-block;
    &:hover, &:focus {
      margin-right: -10px;
      text-decoration: none;
      opacity: 0.5;
      padding-left: 10px;
    }
  }
}

// Coupon Code Page
.page-code #custom-membership-coupon-code-form {
  margin: 40px auto 20px auto;
  width: 300px;
  #edit-code {
    max-width:  150px;
  }
  span.form-required {
    display: none;
  }
}

// International message
.intl-msg {
  padding: 12px;
  margin-bottom: 15px;
  background:rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
}

// Credit Card Number
.form-item-commerce-payment-payment-details-credit-card-number {
  label {
    display: block;
  }
  input {
    display: inline-block;
    margin-right: 10px;
  }
  #cc-type {
    height: 29px;
    width: 46px;
    background-size: 46px 29px;
    display: inline-block;
    margin-bottom: -8px;
    background-repeat: no-repeat;
  }
  span.visa {
    background-image:url('images/cards/visa.png');
  }
  span.mastercard {
    background-image:url('images/cards/mastercard.png');
  }
  span.amex {
    background-image:url('images/cards/amex.png');
  }
  span.discover {
    background-image:url('images/cards/discover.png');
  }
}

// Descriptive text within "Check" label.
.form-item-commerce-payment-payment-method label span {
  font-size: 13px;
  font-style: italic;
}

// Inline Expiration Date and Security Code blocks
// for both
.commerce-credit-card-expiration,
.form-item-commerce-payment-payment-details-credit-card-code {
  display: inline-block;
}

.commerce-credit-card-expiration {
  float: left;
  margin-right: 40px;
}

.form-item-credit-card-exp-month label,
.form-item-commerce-payment-payment-details-credit-card-exp-month label {
 margin-right: 5px;
}

.form-item-credit-card-exp-month .bootstrap-select.form-control:not([class*="col-"]),
.bootstrap-select.stripe.form-control:not([class*="col-"]) {
  width: inherit;
}

.form-item-credit-card-code,
.form-item-commerce-payment-payment-details-credit-card-code {
  label {
    display: inline-block;
    margin-right: 5px;
  }
  input {
    display: inline-block;
  }
}

.bootstrap-select.form-control {
  background-color: inherit;
}

.form-item-credit-card-cardonfile-instance-default label {
  display: block;
  clear: both;
  margin-bottom: 20px;
}

// Notification that an item is already in the cart.
.custom-signup-cart {
  color: #FFF;
  width: 250px;
  position: fixed;
  left:10px;
  top: 80px;
  z-index: 99999;
  background:rgb(66, 139, 202);
  border: 1px solid rgb(51, 120, 179);
  padding: 20px;
  box-shadow:0 0 60px rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  text-shadow: 1px 1px 4px #000;
  a {
    text-decoration:underline;
    transition: all 300ms;
    color: #FFF;
    &:hover, &:focus {
      opacity: 0.5;
    }
  }
}

#mini-panel-main_menu .panel-panel {
  margin-bottom: 0;
}

// Remove redundant fieldset formatting for addressfield-wrapper
// #addressfield-wrapper fieldset {
//   border: none;
//   box-shadow: none;
//   .panel-body {
//     padding: 0;
//   }
// }

// Place checkout fieldsets in columns.
#commerce-checkout-form-checkout {
  @media @tablet {
    #edit-cart-contents {
      display: inline-block;
      width: 47%;
      float: right;
      margin-left: 3%;
    }
  }
}

#edit-commerce-payment {

  .panel-body {
    padding-top: 60px;
    background:url('images/credit_cards.png') 16px 14px no-repeat;
    background-size: 200px 29px;
  }
}

// Hide cardonfile default checkbox
body.page-checkout {
  .form-item-commerce-payment-payment-details-cardonfile-instance-default {
    display: none;
  }
}

// Stripe payment error.
form#commerce-checkout-form-checkout .messages.error,
#commerce-stripe-validation-errors {
  padding: 16px;
  text-align: left;
  border-radius: 5px;
}

form#commerce-checkout-form-checkout .messages.error {
  margin-bottom:  20px;
}
