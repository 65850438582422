#the-catechetical-review-logo {
  margin-bottom: 0;
  padding-top: 50px;
  padding-bottom: 16px;
  .pane-content {
    text-align: center;
    img {
      max-width: 500px;
    }
  }
}

.pane-menu-menu-the-catechetical-review {
  .menu.nav {
    > li.dropdown {
      &:hover {
        .dropdown-menu {
          display: block;
        }

        /* Optional hover effect for parent menu item */
        background-color: #f8f8f8;
      }

      .dropdown-menu {
        margin-top: 0; // Ensure proper positioning
        > li.active > a {
          background-color: inherit;
          color: #333;
          &:hover {
            background-color: inherit;
            color: #333;
            cursor: default;
          }
        }
        a {
          color: #337ab7;
          &:hover {
            color: #23527c;
          }
        }
      }
    }
  }
}

#the-catechetical-review-menu {
  margin: 0;
  padding: 16px 0 16px 0;
}

#tcr-explanation {
  max-width: 750px;
  margin: 12px auto 24px;
}

#tcr-intro {
  margin-top: 30px;
  display: flex;
  gap: 30px;
  .left {
    img {
      max-width: 250px;
    }
  }
  .right {
  }
}

.view-issues,
.view-categories,
.view-authors {
  .view-content {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }
}

.view-issues .view-content {
  justify-content: center;
}

.view-categories .view-content {
  padding-bottom: 25px;
  .views-row {
    width: 265px;
  }
}

.view-authors .view-content .views-row {
  width: 265px;
}

.node-type-article .radix-layouts-sidebar h3 {
  font-size: 22px;
}

.pane-node-field-category span.pane-title {
  display: none;
}

.pane-menu-menu-the-catechetical-review {
  .pane-content {
    display: flex;
    justify-content: center;
  }
  ul {
    .nav-pills;
  }
}

.tcr-category,
.tcr-categories {
  h2 {
    font-size: 24px;
  }
}

.tcr-categories ul {
  list-style-position: inside;
  padding-left: 0;
}

body.i18n-es .field-name-field-author .field-item:before,
body.i18n-es .article-author:before,
body.i18n-es .view-related-articles .views-field-field-author .field-content:before {
  content: "Por";
}

.pane-related-articles-panel-pane-1 {
  padding: 16px 16px 0 20px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.08);

  h2.pane-title {
    font: 28px / 1.2 "EB Garamond", Georgia, "Times New Roman", Times, serif;
    line-height: 1.4em;
    font-weight: 400;
    margin-bottom: 22px;
  }
  h4 {
    font-family: "Eb Garamond", Georgia, serif;
  }
  .views-field-field-author {
    margin: -4px 0 30px 0;
    .field-content:before {
      display: inline-block;
      content: "By";
      margin-right: 5px;
    }
  }
}


.node-type-article {
  .pane-page-title h1 {
    text-align: center;
    font: 40px/1.2 "EB Garamond", Georgia, "Times New Roman", Times, serif;
    max-width: 700px;
    margin:0 auto;
  }
  .field-name-field-author .field-item {
    text-align: center;
    margin-bottom: 40px;
    &:before {
      display: inline-block;
      content: "By";
      margin-right: 5px;
    }
  }

  .pane-node-field-related-articles {
    h2 {
      font: 28px/1.2 "EB Garamond", Georgia, "Times New Roman", Times, serif;
      line-height: 1.4em;
      font-weight: 400;
    }
    .field-item:not(:last-child){
      margin-bottom: 16px;
    }
  }

  .pane-node-field-footnotes {
    .pane-title {
      font-weight: bold;
      margin-bottom: 18px;
    }
  }

  .node-teaser.node-author .radix-layouts-content > div {
    padding: 16px 26px 2px 16px;
    background: rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    @media (max-width: 769px) {
      flex-direction: column;
    }
    .pane-node-field-image {
      min-width: 150px;
      margin: -16px 20px 12px -16px;
      @media (max-width: 769px) {
        min-width: 100px;
      }
    }
    .field-name-body {
      h1:first-child,
      h2:first-child,
      h3:first-child {
        margin-top: 0;
      }
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  // Articles from the Most Recent Issue
  .current-issue-title {
    text-align: center;
    margin-top: 50px;
    padding-top: 30px;
    border-top: 1px solid #5D5D5D;
    font-family: "EB Garamond", Georgia, "Times New Roman", Times, serif;
  }
  .pane-articles-panel-pane-4 {
    h2 {
      font-size: 26px;
    }
    h3 {
      font-size: 20px;
    }
    .view-content {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
      .views-row {
        flex: 1 1 calc(50% - 20px);
        text-align: center;
        &:first-child {
          flex-basis: 100%;
          > div {
            max-width: 350px;
            margin: 0 auto;
          }
        }
        &.views-row-last {
          flex: 0 0 calc(50% - 20px); /* Ensures the last child also takes half-width */
        }
        .article-author {
          font-size: 14px;
          margin-top: -4px;
          &:before {
            display: inline-block;
            content: "By";
            margin-right: 5px;
            font-size: 14px;
          }
        }
        h3 {
          font-family: "EB Garamond", Georgia, "Times New Roman", Times, serif;
        }
      }
    }
  }

  .radix-layouts-content .pane-node-body {
    h1,h2,h3,h4,h5,h6 {
      font: 28px/1.2 "EB Garamond", Georgia, "Times New Roman", Times, serif;
      line-height: 1.4em;
      font-weight: 400;
    }
    span.floatright img {
      margin:0 0 12px 12px;
    }

    span.floatleft img {
      margin:0 12px 12px 0;
    }
    p {
      line-height: 1.7;
    }
  }
  @media (min-width: 768px) {
    .col-sm-4 {
      padding-left: 40px;
      font-size: 14px;
      line-height: 1.4;
    }
  }
  .pane-custom.pane-1 {
    font-size: 14px;
  }
  .pane-node-field-article-pdf {
    .file-icon {
      display: none;
    }
    .file {
      a {
        &:extend(.btn, .btn-success);
      }
    }
  }
}

.view-issues.view-display-id-panel_pane_1 {
  .issue-number {
    text-align: center;
    font: 30px / 1.2 "EB Garamond", Georgia, "Times New Roman", Times, serif;
    max-width: 700px;
    margin: 0 auto;

  }
  .issue-title {
    text-align: center;
    font: 40px / 1.2 "EB Garamond", Georgia, "Times New Roman", Times, serif;
    max-width: 700px;
    margin: 0 auto;
  }
  .views-field-field-image {
    margin: 30px 0 40px;
    text-align: center;
  }

  border-bottom: 1px solid #333;
  margin-bottom: 50px;
}
