.view-workshop-presenter {
  .view-content {
    padding: 12px 0;
  }
  .views-row:not(.views-row-first) {
    padding-top: 20px;
    border-top: 1px solid #ccc;
  }
  .views-row:not(.views-row-last) {
    margin-bottom: 20px;
  }
  .views-field-field-workshop-presenter-image {
    margin-bottom: 10px;
    text-align: center;
    .field-content img {
      display: inline-block;
      padding-bottom: 12px;
      background: url(images/bg-shadow-sm.png) no-repeat center bottom;
    }
  }
  .views-field-title {
    font: italic 16px/20px @sans;
    margin-bottom: 10px;
    text-align: center;
  }
  .views-field-field-workshop-presenter-bio {
    font: 14px/20px 'Open Sans';
    text-align: left;
    font-size: 12px;
    line-height: 15px;
  }
}

.pane-node-field-workshop-presenter-image {
  .field-items img {
    display: inline-block;
    padding-bottom: 12px;
    background: url(images/bg-shadow-sm.png) no-repeat center bottom;
  }
}

.modal-body {
  .panel-pane {
    border-style: hidden !important;
    .pane-content {
      background: white !important;
      padding: 0 !important;
      text-align: left !important;
      .field-name-field-workshop-presenter-bio {
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        font-size: 15px !important;
        line-height: 1.42857143 !important;
        color: #333333 !important;
      }
    }
  }
}