fieldset.captcha {
  background-color: transparent;
  border: transparent;
  box-shadow: none;

  legend {
    display: none;
  }

  .panel-body {
    padding: 0;
  }
}
