body.node-unpublished {
  background: @gold;
}

.pane-page-title h1 {
  font-size: 30px;
  line-height: 34px;
}

.pane-page-title,
.pane-page-messages,
.pane-page-tabs,
.pane-page-breadcrumb,
.pane-page-content,
.pane-page-actions,
#the-catechetical-review-logo,
#the-catechetical-review-menu {
  background-color: @tan;
}

body.dashboard,
body.page-user-edit,
body.page-freecourses-user-edit,
body.page-user-mooc-edit {

  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background-color: #E9EAED;
  }

  .pane-page-title,
  .pane-page-messages,
  .pane-page-tabs,
  .pane-page-breadcrumb,
  .pane-page-content {
    background-color: @nav-tabs-border-color;
  }
}

tr.remove-leader-row,
tr.remove-learner-row {
  .loading {
    span {
      display: inline-block;
      padding-right: 15px;
    }
    .confirm-remove-leader,
    .confirm-remove-learner {
      display: inline-block;
      background: #5cb85c url('/misc/css-loader.gif') no-repeat 2px 2px;
      background-size: 12px 12px;
      padding-left: 18px;
      pointer-events: none;
    }
  }
}
