.pane-panels-mini.pane-main-menu,
.pane-panels-mini.pane-main-menu-mooc {
  background: @gold;
  margin-bottom:0;
  padding: 24px 0 18px;
  position: relative;
  z-index: 1;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;

  // Center logo on mobile and tablet.
  @media @mobile-only {
    .pane-white-logo {
      text-align: center;
      img {
        margin-bottom: 16px;
      }
    }
  }

  @media @tablet-only {
    .pane-white-logo {
      text-align: center;
      img {
        margin-bottom: 16px;
      }
    }
  }

  // Make tablet view emulate mobile-only
  @media @tablet-only {
    .radix-layouts-column1,
    .radix-layouts-column2 {
      width: 100%;
    }
  }

  // Welcome message.
  .pane-welcome-message {
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 10px;
  }

  // User menu
  .pane-system-user-menu,
  .pane-menu-menu-mooc-user-menu {
    ul {
      margin-bottom: 0;
    }
    .list-inline {
      text-align: center;
      > li {
        display: inline-block;
        padding: 0;
      }
    }
    span.icon {
      margin-right: 6px;
      font-size: 16px;
    }
  }

  // Links
  li {
    a {
      display: inline-block;
      color: #FFF;
      padding: 8px 10px;
      @media @mobile-only {
        padding: 6px 8px;
        font-size: 12px;
      }
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.2);
      margin-top: 8px;
      margin-right: 4px;
      &:hover, &:focus {
        text-decoration: none;
        background: rgba(0, 0, 0, 0.5);
      }
      // border-left: 1px solid #000;
      // border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
    &:first-child a {border-left: none;}
    &:last-child a {border-right: none;}
  }
}

// Dim "My Dashboard" link when on dashboard.
body.page-user.dashboard .pane-system-user-menu li.first a {
  opacity: 0.3;
  cursor: default;
  &:hover, &:focus {
    background: rgba(0, 0, 0, 0.2);
  }
}

// Dim "Questions &  Answers" link when on FAQ.
body.page-faq .pane-system-user-menu li#faq a,
body.page-faq .pane-menu-menu-mooc-user-menu li#faq a,
body.page-user.dashboard .pane-menu-menu-mooc-user-menu li#mooc-dashboard a,
body.page-mooc .pane-menu-menu-mooc-user-menu li#home a {
  opacity: 0.3;
  cursor: default;
  &:hover, &:focus {
    background: rgba(0, 0, 0, 0.2);
  }
}

// Hide welcome message when empty.
@media (max-width: 991px) {
  #empty-welcome-msg {
    display: none;
  }
}

// Slightly smaller text size for Espanol main menu for non-logged in users so
// the menu doesn't go to two lines.
body.not-logged-in.i18n-es .pane-panels-mini.pane-main-menu li a {
  font-size: 14px;
}
