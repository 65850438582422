.green-submit-mooc {
  color: @mooc-btn-text;
  background-color: @mooc-btn;
  border-color: @mooc-btn-border;
}

.mooc-page,
.node-type-mooc-course,
.node-type-mooc-session {
  .btn {
    border-radius: 0;
  }
  .btn-default,
  #custom-diocese-internal-notes-form .form-actions button,
  a#edit-cancel {
    color: @mooc-btn-text;
    background-color: @mooc-btn;
    border-color: @mooc-btn-border;
  }
  .btn-default:focus,
  .btn-default.focus {
    color: @mooc-btn-text;
    background-color: @mooc-btn-focus;
    border-color: @mooc-btn-focus-border;
  }
  .btn-default:hover {
    color: @mooc-btn-text;
    background-color: @mooc-btn-hover;
    border-color: @mooc-btn-hover-border;
  }
  .btn-default:active,
  .btn-default.active,
  .open > .dropdown-toggle.btn-default {
    color: @mooc-btn-text;
    background-color: @mooc-btn-active;
    border-color: @mooc-btn-active-border;
  }
  .btn-default:active:hover,
  .btn-default.active:hover,
  .open > .dropdown-toggle.btn-default:hover,
  .btn-default:active:focus,
  .btn-default.active:focus,
  .open > .dropdown-toggle.btn-default:focus,
  .btn-default:active.focus,
  .btn-default.active.focus,
  .open > .dropdown-toggle.btn-default.focus {
    color: @mooc-btn-text;
    background-color: @mooc-btn-active-hover;
    border-color: @mooc-btn-active-hover-border;
  }
  .btn-default:active,
  .btn-default.active,
  .open > .dropdown-toggle.btn-default {
    background-image: none;
  }
  .btn-default.disabled:hover,
  .btn-default[disabled]:hover,
  fieldset[disabled] .btn-default:hover,
  .btn-default.disabled:focus,
  .btn-default[disabled]:focus,
  fieldset[disabled] .btn-default:focus,
  .btn-default.disabled.focus,
  .btn-default[disabled].focus,
  fieldset[disabled] .btn-default.focus {
    background-color: @mooc-btn;
    border-color: @mooc-btn-border;
  }
  .btn-default .badge {
    color: @mooc-btn-text;
    background-color: @mooc-btn-text;
  }
  .btn-primary {
    color: @mooc-btn-text;
    background-color: @mooc-btn;
    border-color: @mooc-btn-border;
  }
  .btn-primary:focus,
  .btn-primary.focus {
    color: @mooc-btn-text;
    background-color: @mooc-btn-focus;
    border-color: @mooc-btn-focus-border;
  }
  .btn-primary:hover {
    color: @mooc-btn-text;
    background-color: @mooc-btn-hover;
    border-color: @mooc-btn-hover-border;
  }
  .btn-primary:active,
  .btn-primary.active,
  .open > .dropdown-toggle.btn-primary {
    color: @mooc-btn-text;
    background-color: @mooc-btn-active;
    border-color: @mooc-btn-active-border;
  }
  .btn-primary:active:hover,
  .btn-primary.active:hover,
  .open > .dropdown-toggle.btn-primary:hover,
  .btn-primary:active:focus,
  .btn-primary.active:focus,
  .open > .dropdown-toggle.btn-primary:focus,
  .btn-primary:active.focus,
  .btn-primary.active.focus,
  .open > .dropdown-toggle.btn-primary.focus {
    color: @mooc-btn-text;
    background-color: @mooc-btn-active-hover;
    border-color: @mooc-btn-active-hover-border;
  }
  .btn-primary:active,
  .btn-primary.active,
  .open > .dropdown-toggle.btn-primary {
    background-image: none;
  }
  .btn-primary.disabled:hover,
  .btn-primary[disabled]:hover,
  fieldset[disabled] .btn-primary:hover,
  .btn-primary.disabled:focus,
  .btn-primary[disabled]:focus,
  fieldset[disabled] .btn-primary:focus,
  .btn-primary.disabled.focus,
  .btn-primary[disabled].focus,
  fieldset[disabled] .btn-primary.focus {
    background-color: @mooc-btn;
    border-color: @mooc-btn-border;
  }
  .btn-primary .badge {
    color: @mooc-btn;
    background-color: @mooc-btn-text;
  }
}