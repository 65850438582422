// Maximum width for better readability
.node-type-forum {
  .pane-page-title .pane-content,
  .pane-page-tabs .pane-content,
  .pane-page-content .pane-content {
    max-width: 900px;
    margin: 0 auto;
  }

  a.ckeditor_links {
    display: none;
  }

  img {
    max-width: 100%;
    height: auto !important;
  }

  .well {
    display:  flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    .btn-sm {
      font-size: 12px;
    }
    @media @mobile-only {
      flex-direction: column;
      .btn {
        margin-top: 8px;
      }
    }
  }



  h1 {
    margin: 30px 0 22px;
  }

  .flag-outer {
    margin-top: 50px;
    .flag-wrapper {
      display: block;
      width: 100%;
    }
    a {
      background: #FFF;
      border: 1px solid #83b4de;
      padding: 3px 8px 4px;
      font-size: 12px;
      &:hover {
        background: #3279b7;
        border: 1px solid #3279b7;
        color: #fff;
        text-decoration: none;
      }
    }
    .flag-message {
      padding: 15px 0;
      width: 100% !important;
    }
  }
}

// Comment indents.
.indented {
  margin-left: 50px;
}

form.comment-form {
  margin-top: 50px;
}

.comment-wrapper {

  margin-top: 50px;

  // First Comment.
  > .indented {
    margin-left: 50px;
  }

  // Disable reply button for indented comments.
  .indented .comment-reply a {
    display: none;
  }

  // All Comments.
  .comment {
    border: 1px solid #9b8547;
    background: #ede9dc;
    border-radius: 5px;
    margin: 26px 0;
    position: relative;
    display: flex;
    ul.links {
      margin-bottom: 0;
      margin-top: 20px;
    }
    .confirmation {
      padding: 20px;
    }
    .content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 15px;
    }
    .submitted {
      text-align: center;
      border-right: 1px solid #9b8547;
      background: rgba(155, 133, 71, 0.1);
      padding: 20px;
      margin-bottom: 0;
      font-style: normal;
      color: #444;
      font-size: 14px;
      max-width: 170px;
      min-width: 170px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .photo img {
        border-radius: 50%;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .4);
      }
      .name {
        margin-top: 12px;
        font-weight: bold;
      }
    }

  }

  .comment {
    background-color: #faf7ee;
    ul.links {
      display: block;
      a {
        background: #FFF;
        border: 1px solid #83b4de;
        padding: 3px 8px 4px;
        font-size: 12px;
        &:hover {
          background: #3279b7;
          border: 1px solid #3279b7;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

}

.view-forum-listing {
  .views-exposed-form .views-exposed-widget .btn {
    margin-top: 0;
  }
  .views-widget-filter-tid {
    display: flex;
    align-items: center;
    padding: 30px 0 12px 0;
    label {
      margin-right: 10px;
    }
  }
}

.page-discussions .action-links {
  margin-top: 40px;
  a {
    transition: all 300ms;
    &:extend(.btn, .btn-sm, .btn-success);
    &:hover, &:focus {
      text-decoration: none;
      transform: translateY(2px);
      opacity: 0.9;
    }
  }
}
