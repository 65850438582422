#site-credit {
  background: @gold;
  margin-bottom: 0;

  .pane-content {

    text-align: right;
    color: #FFF;
    padding: 12px;
    font: 13px/15px @serif;
    span {
      opacity: 0.6;
    }

    img {
      transition: opacity 500ms;
      margin-top:-16px;
    }

    &:hover, &:focus {
      img {opacity: 1 !important;}
    }

    a {
      text-decoration: none;
      border-bottom: 1px dashed #FFF;
      color: #FFF;
      display: inline-block;
      opacity: 0.6;
      transition: all 300ms;
      margin-right: 14px;


      &:hover, &:focus {
        opacity: 1;
        border-bottom: none;
        margin-left: 16px;
        transform:scale(1.2, 1.2);
      }
    }

  }
}
