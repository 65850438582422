/**
 * institution Nodes
 */

body.node-type-institution {
  .pane-page-content,
  .pane-page-tabs,
  .pane-page-messages {
    background-color: @tan;
  }
  h3.pane-title {
    text-align: center;
    margin-bottom: 16px;
  }
}

.node-type-institution .panel-pane.pane-page-tabs {
  padding-top: 2em;
}

.pane-custom-learner-signup-button {
  margin-bottom: 0;
  margin-top: 2em;
}

/* Diocesan Crest, Name, and Introduction */
.subtheme-institution-top {
  text-align: center;
  padding-bottom: 40px;
  .panel-separator {display: none;}
  .pane-node-field-institution-image {
    display: inline-block;
    margin-bottom: 0;
  }
  .pane-node-title {
    display: inline-block;
    h1.title {
      margin-top: 40px;
      font: 50px/50px @serif;
      @media (max-width: 767px) {
        font: 30px/30px @serif;
      }
      padding-bottom: 55px;
      background: url('images/accent.png') no-repeat center bottom;
      background-size: 175px 31px;
    }
  }
  .pane-node-field-institution-description {
    text-align: left;
  }
}

/* Diocesan Tracks and Workshops */
.panel-pane.pane-custom-institution-tracks {
  margin-bottom: 30px;
  .panel-body {
    padding-top: 0;
    .panel-body {
      padding-top: 12px;
    }
  }
  .panel-default > .panel-heading {
    transition: background-color 200ms;
    &:hover, &:focus {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .panel-heading .panel-title a {
    text-decoration: none;
    padding-left: 28px;
    background: @right-arrow;
    background-size: 20px 20px;
    &.expanded {
      background: @down-arrow;
      background-size: 20px 20px;
    }
  }

  .course-title {
    font: bold 18px/24px @sans;
    margin: 15px 0 7px;
  }

  .workshop-category-title {
    font: 300 italic 18px/24px @sans;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 6px;
  }

  > div > div > div {
    margin-bottom: 8px;
  }

  /* Workshops */
  .panel .panel {
    margin-left: 20px;
    margin-bottom: 8px;
    .panel-heading {
      padding: 5px 8px;
      padding-left: 3pxpx;
    }
    .panel-title a {
      font-size: 14px;
      background-size: 15px 15px;
      padding-left: 23px;
      &.expanded {
        background-size: 15px 15px;
      }
    }
  }
}

/* Online Registration Buttons */
body.node-type-institution {
  a.register-individual, a.register-institution, button.active-subscription {
    border-radius: 5px;
    padding: 16px 24px;
    color: #FFF;
    margin-bottom: 20px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
    display: inline-block;
    transition: opacity 300ms;
    &:hover, &:focus {
      text-decoration: none;
      opacity: 0.5;
    }
  }
  button.active-subscription {
    background: @blue;
  }
  a.register-individual {
    padding-top: 65px;
    background: url(images/person.png) no-repeat center 12px @blue;
    background-size: 50px 50px;
  }
  a.register-institution {
    padding-top: 86px;
    background: url(images/church.png) no-repeat center 12px @blue;
    background-size: 44px 70px;
  }
}

/* When editing institution nodes */
@media @tablet {
  #edit-field-institution-officials {
    width: 45%;
    margin-top: 40px;
    margin-right: 4%;
    display: inline-block;
    float: left;
  }

  #edit-field-institution-mentors {
    width: 45%;
    margin-top: 40px;
    display: inline-block;
  }

  div#edit-field-institution-mentor-welcome {
    clear: both;
    padding-top: 20px;
  }

  .node-institution-form .group-general-demographic.field-group-fieldset {
    clear: both;
    margin-top: 35px;
  }
}

// When editing General Diocesan Demographics

.node-institution-form {
  #edit-general-diocesan-demographic {
    margin-top: 40px;
    .help-block {
      margin-bottom: 20px;
      max-width: 474px;
      &:extend(.alert, .alert-success);
    }
  }

  #edit-field-institution-bishop {
    margin-bottom: 30px;
  }

  #edit-field-institution-number-parishes,
  #edit-field-institution-num-high-schools,
  #edit-field-institution-num-deacons,
  #edit-field-institution-cath-pop {
    float: left;
    margin-right: 50px;
    width: 220px;
    height: 100px;
  }

  #edit-field-institution-num-priests {
    clear: both;
    float: left;
    margin-right: 50px;
    width: 220px;
  }

  #edit-field-institution-cath-pop {
    width: 280px;
  }

  #edit-field-institution-num-priests,
  #edit-field-institution-num-deacons,
  #edit-field-institution-cath-pop {
    height: 65px;
  }

}
