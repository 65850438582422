#marketing-top-bar {
  margin-bottom: 0;
  background: @green;

  // Link to Franciscan.edu
  .link-to-fus {
    display: inline-block;
    float: right;
    margin-bottom: 0;
    @media @mobile-only {
      display: block;
      float: none;
      text-align: center;
    }
    .pane-content {
      margin-top: 8px;
      margin-bottom: 6px;
    }
    .pane-content,
    .pane-content a {
      font: 13px/20px @serif;
    }
  }

  // Franciscan at Home (Link to Front Page)
  .lms-home-link {
    margin-top: 8px;
    margin-bottom: 6px;
    display: inline-block;
    float: left;
    span.icon {
      font-size: 14px;
      margin-right: 4px;
    }
    @media (max-width: 767px) {
      float: none;
      text-align: center;
      display: block;
    }
  }

  // Top Right Menu
  .pane-custom-core-top-right-menu {
    margin-top: 8px;
    margin-bottom: 0;
    display: inline-block;
    float: right;
    @media (max-width: 767px) {
      float: none;
    }
    .form-group {
      margin-bottom: 0;
    }

    .bs-searchbox input {
      background-color: rgba(255, 255, 255, 0.6);
      color: #000;
      font: 14px/16px @sans;
    }
    .btn-group.open .dropdown-toggle {
      box-shadow: none;
    }
    .bootstrap-select {
      .dropdown-menu {
        margin-top: 0;
      }
      &:hover, &:focus {
        .dropdown-menu {
          display: block;
        }
      }
    }
    .dropdown-menu {
      background: lighten(@green, 5%);
    }
    .bootstrap-select.btn-group .no-results {
      background: @green;
      color: #FFF;
      font-family: sans;
      font-style: italic;
      margin: 0;
      padding: 5px 10px;
    }
    .btn {
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
      padding-right: 22px;
    }
    .form-control {
      height: 26px;
      line-height: initial;
      box-shadow: none;
      color: #FFF;
      background-color: transparent;
    }
    .btn-default {
      background-color: @green;
      color: #FFF;
      // text-shadow: 1px 1px 4px #000;
      border: none;
    }
    ul {
      margin-bottom: 0;
      &.dropdown-menu  {
        li:first-child {
          display: none;
        }
      }
    }
    .filter-option {
      line-height: 20px;
    }
  }

  .pane-content,
  .pane-content a {
    color: #FFF;
    font: 16px/18px @serif;
    // text-shadow: 1px 1px 4px #000;
  }

  /*  Dropdown */
  ul.dropdown-menu {
    background: @green;
    border-radius: 0px;
    margin-top: 0px;
    > li > a {
      &:hover, &:focus {
        color: #FFF;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  // Language Picker
  #block-locale-language {
    margin: 9px 18px 0 0;
    display: inline-block;
    float: right;
    position: relative;
    h2 {
      display: none;
    }
    ul {
      list-style: none;
      li {
        display: inline-block;
        margin-right: 10px;
        &.active {
          opacity: 0.5;
          &:before {
            content: "\2713";
          }
          a:hover {
            text-decoration: none;
            cursor: default;
          }
        }
      }
      /* Remove unwanted language switcher options */
      li.sk, li.lv, li.id, li.zh-hans, li.zh-hant {
        display: none;
      }
    }
  }
}

// Hide "Franciscan at Home" link on front page.

.front #marketing-top-bar .lms-home-link {
  display: none;
}
