body.page-about {

  .pane-page-title {
    display: none;
  }

  .pane-page-content, .about-container-parent {
    padding: 0;
    margin: 0;
    .pane-content {
      box-sizing: content-box;
      width: 100%;
      padding: 0;
      margin: 0;
      h2 {
        font-family: "EB Garamond", Georgia, "Times New Roman", Times, serif;
        text-align: center;
        &:after {
          content: "\A___________";
          white-space: pre-wrap;
          font-family: "Times New Roman", serif;
        }
      }
      #about-heading {
        text-align: left;
        &:after {
          content: "\A_________________________";
        }
      }
    }
  }

  #about-content {
    @media @tablet {
      background: url('/sites/default/files/about-page/gold-fade-background.png') no-repeat center;
      background-size: cover;
      padding: 30px 70px;
    }
    .pane-content {
      display: flex;
      flex-direction: column;
      margin: 0;
      @media @tablet {
        flex-direction: row;
        align-items: center;
      }
      #monstrance {
        flex: 0 1 auto;
        img {
          width: 100%;
        }
      }
      p {
        text-align: justify;
      }
    }
  }

  #select-cards {
    background-image: radial-gradient(50% 104%, #457654 46%, #21412A 100%);
    .pane-content {
      .card-deck {
        padding: 20px;
        display: grid;
        grid-template-columns: 200px 200px;
        @media @tablet {
          grid-template-columns: 200px 200px 200px 200px;
        }
        grid-template-rows: auto;
        justify-content: center;
        gap: 20px 20px;
        .card {
          cursor: pointer;
          background: #9b8547;
          border: 1px solid #FFFFFF;
          border-radius: 6px;
          a {
            text-decoration: none;
          }
          .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            .card-img {
              width: 120px;
              height: 120px;
              margin: 5px;
            }
            .card-text {
              font-size: 2rem;
              background: #FFFFFF;
              color: #337ab7;
              width: 100%;
              height: 6rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;
            }
            .card-img {
              .opacity-hover(.5);
            }
          }
          &:hover, &:focus {
            box-shadow: 0 0 6px 6px rgba(255, 255, 255, 0.25);
          }
        }
      }
    }
  }

  #our-collaborators {
    display: none;
    padding: 0 30px;
    overflow: hidden;
    @media @tablet {
      background: url(/sites/default/files/about-page/chapel-background.jpg) no-repeat fixed center;
      background-size: cover;
    }
    h2 {
      margin: 20px 0 1.5em 0;
    }
    .view-content {
      //column-width: 480px;
      //column-gap: 30px;
      //margin-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .views-row {
        //break-inside: avoid-column;
        //page-break-inside: avoid;
        display:  flex;
        align-items: center;
        background: #FFF;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
        margin-bottom: 20px;
        padding: 10px 20px;
        height: 80px;
        position: relative;
        border-radius: 5px;
        flex: 0 100%;
        @media (min-width: 992px) {
          flex: 0 48%;
        }
        @media (min-width: 1500px) {
          flex: 0 32%;
        }
        transition: all 300ms;
        &:hover {
          transform: translateX(10px);
        }
        img {
          max-width: 120px;
          max-height: 50px;
          width: auto;
        }
        .views-field-field-collaborator-image {
          min-width: 120px;
          text-align: center;
          margin-right: 30px;
        }
        .views-field-title {
          font-size: 15px;
          a {
            color: #444;
            &:after {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 1;
              pointer-events: auto;
              content: "";
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  #our-partner-dioceses {
    display: none;
    padding: 0 30px 50px;
    @media @tablet {
      background: #FFFFFF url(/sites/default/files/about-page/stone-wall-background.jpg) no-repeat fixed center;
      background-size: cover;
    }
    h2 {
      margin: 20px 0 1.5em 0
    }
    .pane-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      gap: 30px;
      &:before {
        content: ' ';
        display: inline-block;
        position: relative;
        width: 500px;
        height: 700px;
        background: url('/sites/default/files/about-page/san-damiano-crucifix.png') no-repeat scroll center;
        background-size: 500px 700px;
      }
      .view-participating-dioceses {
        height: 84rem;
        width: 60rem;
        .view-header {
          padding-bottom: 20px;
          text-align: center;
        }
        .view-content {
          height: 80%;
          overflow-y: auto;
          border: solid 3px #B5B5B5;
          background: rgba(255, 255, 255, 0.7);
          padding: 10px;
        }
        .views-field-field-diocese-image {
          width: 27px;
          margin: 0 12px;
          img {
            width: 17px;
          }
        }
        .views-row {
          display: flex;
          min-height: 35px;
        }
      }
    }
  }

  #our-events {
    h2, h3, h4 {
      font-family: "EB Garamond", Georgia, "Times New Roman", Times, serif;
    }
    display: none;
    padding: 30px;
    overflow: hidden;
    @media @tablet {
      background: url(/sites/default/files/about-page/altar-background.jpg) no-repeat fixed center;
      background-size: cover;
    }
    #mini-panel-our_events {
      position: relative;
      padding-bottom: 100px;
      padding-top: 30px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: stretch;
      gap: 50px;
      .panel-col-first {
        width: 500px;
        #event-pdf-documents {
          background: rgba(255, 255, 255, 0.4);
          padding: 35px;
          h2, h4 {
            margin-top: 0;
            font-size: 24px;
          }
          dl {
            margin-bottom: 20px;
            dd {
              margin-bottom: 4px;
            }
          }
        }
        #scroll-up-button {
          position: absolute;
          padding: 20px 30px;
          border: solid 2px white;
          border-radius: 20px;
          color: white;
          background-color: #9b8547;
          text-align: center;
          vertical-align: middle;
          font-size: 24px;
          bottom: 30px;
          left: 30px;
          text-decoration: none;
          display: block;
          &:hover, &:focus {
            box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.25);
          }
        }
      }
      .panel-col-last {
        .ctools-collapsible-processed:not(.ctools-collapsed) .ctools-toggle {
          display: none;
        }
        .ctools-collapsed {
          h2 {
            font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-align: left;
            font-size: 24px;
            &:after {
              content: "";
            }
          }
        }
        h4 {
          font-size: 24px;
        }
        .event-row {
          display: flex;
          flex-wrap: nowrap;
        }
        .event-day {
          flex: 0 0 60px;
          padding-right: 10px;
          margin: 5px 0 5px 30px;
          font-weight: bold;
        }
        .event-title {
          padding-left: 20px;
          margin: 5px 0 5px 0;
          border-left: thin solid black;
        }
      }
    }
  }

  #about-footer {
    padding: 30px;
    background: #FFFFFF;
    font-size: 30px;
    font-family: Allura, cursive;
  }
}
