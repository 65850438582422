#footer {
  margin-bottom: 0;

  > .pane-content {
    font: 16px/1.42857143 @serif;
    color: #FFF;
    padding-top: 30px;
    padding-bottom: 30px;
    @media @mobile-only {
      padding: 30px 20px;
    }
    a {
      color: #FFF;
      background: transparent;
    }
    a.btn {
      &:hover, &:focus {
        border-color: #ccc;
        background: rgba(255,255,255,0.2);
      }
      @media @mobile-only {
        .clearfix();
        margin-bottom: 12px;
      }
      @media @tablet-only {
        .clearfix();
        margin-bottom: 12px;
      }
      @media @normal-only {
        .clearfix();
        margin-bottom: 12px;
      }
    }
    h2 {
      text-transform: uppercase;
      font: 500 20px/1.1 @serif;
      letter-spacing: 1px;

    }
  }


  .subtheme-footer-bottom .pane-content {
    margin-top: 20px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);

    a {
      color: rgba(255, 255, 255, 0.8);
    }

    li:not(.last) {
      border-right: 1px solid rgba(255,255,255,0.8);
      padding-right: 11px;
    }
  }

  .subtheme-footer-right li {
    line-height: 1;
    margin-bottom: 8px;
  }

}
