.pane-panels-mini.pane-learner-dashboard {
  > .pane-title {
    &:extend(.green-pane-title);
  }
  font-size: 14px;

  .view-learner-tracks {
    font-size: 16px;
  }

  .pane-learner-tracks-panel-pane-1,
  .pane-learner-tracks-panel-pane-2,
  .pane-learner-workshops-panel-pane-1,
  .pane-learner-workshops-panel-pane-3,
  .pane-custom-bookmark-list,
  .pane-content {
    &:extend(.green-light-bg);
  }
}

.pane-panels-mini.pane-mooc-dashboard {
  > .pane-title {
    &:extend(.green-pane-title);
  }
  font-size: 16px;
  .pane-mooc-dashboard {
    .pane-content {
      &:extend(.green-light-bg);
    }
  }
}
