#view-workshop-offerings {
  background: @gold;
  margin-bottom: 0;
  padding: 30px 20px;
  text-align: center;

  a {
    margin-right: 18px;
    @media (max-width: 620px) {
      margin-bottom: 20px;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    color: #FFF;
    display: inline-block;
    padding: 14px;
    border: 1px solid #FFF;
    font: 18px/21px @sans;
    @media (max-width: 991px) {
      font: 16px/18px @sans;
    }
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.05) 100%);
    transition: all 200ms;
    padding: 14px;
    border-radius: 8px;
    border: 1px solid #FFF;
    transition: all 300ms;

    &:hover {
      text-decoration: none;
      transform: translateY(-8px) scale(1.04);
      background-color: #826d36;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.7);
    }
  }
}
