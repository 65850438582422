.ui-dialog {
    padding: 0;
    max-width: 60vw !important;;
    left: 20vw !important;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.ui-dialog-titlebar {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    background: #3A6646;
    color: #FFF;
}

.ui-widget-header .ui-state-default {
    border: 0;
    background: transparent;
    color: #FFF;
}

.ui-state-default .ui-icon {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAUCAYAAAEcp+bfAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAEaADAAQAAAABAAAAFAAAAABluz5LAAABw0lEQVQ4EX2UvS+DURjFWyU0JiQ11NBELCLaiURE0tlisFosdpFIxGAzdOEPEJtVJGIVsVASXUgQYjIQEU0k9dXX79zee71vPzzJ6XnOec69t71921isvoIgmKr3Qppxr5E0nyBw4l2NN6xIYCyZRNMXprtuQJ8xvfZQSbQZp/biT1qRJtAFkmrSQJUIpeWPyQx75v3hLdgdbuj3IgEnGBwAVdp5Yv+GGFTQxTgFP6A3FTCFaAeqDueJ0VvgO+y17km+gpP6BN4XqN0rjWrQhegLoOy0zpwF5h6gpOt9QA3mI9gHVTATGTrBQFV1Why+j1N0CcQJ5TX0hTGspTKgrOvDAXk5Z9Dfgmujac6Bto4Unmpc52WYDAEdccFX8gL/W6wZJdAPKuSPTRhzDrhabLUDAT18VzZ4BPuLcBulMJ9t4BDWU+ILPQF+gGreD5o1BHZMLAje4AFl4A3rPcGpZusaPILTdpFIC1XbDUFrRD/TX+qeVg+/qq9GXlv5D3Hisjm39geSo9ffxJn17uDulsttuGjDJbj+F7ZuZ7rcyYaNMEfAhw2tNQSswTxvM6KCzyFW7aAMZ/2gRUOmB+hjqS5B5y8RZ/KsxvDWCAAAAABJRU5ErkJggg==') no-repeat;
    background-size: 12px 12px;
}
