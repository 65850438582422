.green-pane,
.pane-panels-mini.pane-institutional-leader-dashboard {

  > .pane-title {
    &:extend(.green-pane-title);
 }

 #custom-mentor-assign-table {
   margin-bottom: 0;
   .table {
     margin-bottom: 4px;
   }
 }

  // Your Institution(s) views.
  .pane-custom-institution-leader-institutions-list {
    font-size: 14px;
    .pane-content {
      &:extend(.green-light-bg);
    }
  }

  .pane-custom-institution-learners-list,
  .pane-custom-institution-mentors-list,
  .pane-views-panes {
    &:extend(.panel, .panel-default);

    h2.pane-title {
      &:extend(.panel-heading);
      &:extend(.green-pane-subtitle);
      cursor: pointer;
    }
    .pane-content {
      &:extend(.green-light-bg);
      table {
        background-color: #FFF;
      }
    }
  }

  // Learners needing a mentor.
  .pane-custom-mentor-assign {
    .pane-content {
      &:extend(.green-light-bg);
      table {
        background-color: #FFF;
      }
    }
  }

  // Mentor Learners view.
  .pane-institution-users-for-institutional-leader-dashboard-panel-pane-1 {
    .row {
      padding: 10px 0;
      text-align: center;
    }
    .views-field-field-user-image {
      img {
        border-radius: 50%;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      }
      margin-bottom: 10px;
    }
    .views-field-realname {
      font-style: italic;
    }
    .views-field-name,
    .views-field-field-user-phone,
    .views-field-mail {
      margin-bottom: 5px;
    }
    .views-field-mail {
      margin-top: 8px;
    }
  }
}
