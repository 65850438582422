// Header.
.pane-panels-mini.pane-header {

}

.logged-in .pane-system-user-menu #sample-workshop {
  display:none;
}

body.page-sample-workshop {
  .pane-page-title {
    display: none;
  }
  .pane-system-user-menu #sample-workshop a {
    opacity: 0.4;
    &:hover, &:focus {
      cursor: default;
      background: rgba(0, 0, 0, 0.2);
      opacity: 0.4;
    }
  }
}
