body.page-events {

  .pane-page-title {
    display: none;
  }

  .pane-page-content {
    padding: 0;
    margin: 0;
    .pane-content {
      box-sizing: content-box;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  h2 {
    font-family: "Times New Roman", serif;
    text-align: center;
    &:after {
      content: "\A___________";
      white-space: pre-wrap;
    }
  }

  .pane-our-events {
//    display: none;
//    padding: 30px;
//    overflow: hidden;
    @media @tablet {
      background: url(/sites/default/files/about-page/altar-background.jpg) no-repeat fixed center;
      background-size: cover;
    }
    #mini-panel-our_events {
      padding-top: 30px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: stretch;
      gap: 50px;
      .panel-col-first {
        width: 500px;
        #event-pdf-documents {
          background: rgba(255, 255, 255, 0.4);
          padding: 35px;
          h2, h4 {
            margin-top: 0;
          }

          dl {
            margin-bottom: 20px;

            dd {
              margin-bottom: 4px;
            }
          }
        }
      }
      .panel-col-last {
        width: 800px;
        .pane-4 {
          .current-event-container {
            height: 600px;
            .current-events {
              height: 594px;
              overflow-y: auto;
              border: solid 3px #B5B5B5;
              border-radius: 3px;
              padding: 10px;
            }
          }
          h2 {
            font-size: 24px;
          }
        }
        .ctools-collapsed {
          h2 {
            font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-align: left;
            font-size: 24px;
            &:after {
              content: "";
            }
          }
        }
      }
    }
  }
}
