// Diocesan Official Learners view.
.view-diocesan-official-learners,
.view-institution-manage-learners,
.view-institution-manage-learners-ci {
  font-size: .9em;

  .stats {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    .item {
      margin: 0 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  // Exposed filters.
  .views-exposed-form {
    margin-bottom: 2em;
    .views-exposed-widget {
      .bootstrap-select .btn {
        margin-top: 0;
      }
      .btn {
      margin-top: 1.55em;
      }
    }
  }

  span.backwards {
    display: inline-block;
    transform: rotateY(180deg);
    margin-left: 10px;
  }

  // This solves a problem where the table header keeps repeating when
  // views_inifite_scroll loads more rows.
  .table-responsive {
    thead {
      display: none;
    }
    &:first-child thead {
      display: table-header-group;
    }
    .table {
      margin-bottom: 0;
    }
  }


  // Learner column.
  .user-photo {
    float: left;
    margin-right: 10px;
    a {
      display: inline-block;
    }
    img {
      max-width: 60px;
      border-radius: 50%;
    }
  }
  .user-name {
    margin-left: 70px;
    font-weight: bold;
  }
  .user-mail {
    margin-left: 70px;
    font-size: .9em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user-phone {
    margin-left: 70px;
    font-size: .9em;
  }

  // Track Progress column.
  .views-field-nothing-1 {
    white-space: nowrap;

    li + li {
      margin-top: 10px;
    }

    .track-in-progress {
      color: @in-progress;
    }
    .track-complete {
      color: @complete;
    }
  }
}

.view-institution-manage-learners-ci {
  .views-field-name {
    display: block;
    width: 250px;
    .user-name,
    .user-mail {
      word-wrap: break-word;
    }
  }
  td.views-field-nothing,
  td.views-field-nothing-2 {
      font-size: 12px;
  }
  td.views-field-nothing-2 {
      max-width: 160px;
  }
}

.tooltip-container {
  display: inline-block;
  position: relative;
  .tooltip-formatted {
    min-width: 400px;
    top: 50%;
    left: 100%;
    transform: translate(0, -50%);
    padding: 10px 10px;
    color: #EEEEEE;
    background-color: #444444;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0,0,0,0.5);
    display: none;
    opacity: 0;
    transition: opacity 0.8s;
    text-align: center;
    .tooltip-confirm {
      display: none;
    }
  }
  .tooltip-formatted:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #444444 transparent transparent;
    top: 50%;
    transform:translate(0, -50%);
    left: -20px;
  }
  .tooltip-modal {
    display: inline-block;
    opacity: 1;
  }
}

.tooltip-container:hover .tooltip-formatted {
  display: inline-block;
  opacity: 1;
}

.remove-equivalency {
  min-width: 160px !important;
  a:link {
    color: #EEEEEE;
  }
}