body.dashboard {

  .help-block {
    margin-bottom: 16px;
    font-weight: normal;
  }

  .block-system > div > div > div > div.panel-pane.pane-panels-mini,
  .block-system > div > div > div > div > div.panel-pane.pane-panels-mini,
  .green-pane {
    padding: 20px;
    border-radius: 3px;
    border: 1px solid rgba(183,184,187,.5);
    box-shadow: 0 1px 0 #fff inset;
    background: #FFF;
    margin-bottom: 45px;
    &.pane-diocese-messages {
      padding: 0;
      background: transparent;
      border: 0;
      box-shadow: none;
      .panel-pane .pane-content {
        background: transparent;
      }
    }
  }

  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .pane-title {
    margin-bottom: 15px;
  }

  .view-mentor-learner-submissions {
    .table > thead > tr > td.active,
    .table > tbody > tr > td.active,
    .table > tfoot > tr > td.active,
    .table > thead > tr > th.active,
    .table > tbody > tr > th.active,
    .table > tfoot > tr > th.active,
    .table > thead > tr.active > td,
    .table > tbody > tr.active > td,
    .table > tfoot > tr.active > td,
    .table > thead > tr.active > th,
    .table > tbody > tr.active > th,
    .table > tfoot > tr.active > th {
      background-color: transparent;
    }
  }

  // Mentor Assignment
  #custom-mentor-assign-form,
  #custom-mentor-assign-form--2 {
    font-size: 14px;
    .btn {
      font-size: 12px;
      padding-top:4px;
      padding-bottom: 4px;
    }
    .dropdown-menu {
      font-size: 14px;
    }
    td {
      vertical-align: middle;
      padding: 4px 8px;
    }
  }

  // Learner Dashboard Panes
  .pane-mentor-learner-submissions-unlinked,
  #mini-panel-learner_dashboard .panel-pane,
  .green-pane .panel-pane,
  .green-pane .panel-display,
  .pane-custom-mentor-assign,
  .pane-mentor-learners-panel-pane-1,
  .pane-mentor-learner-submissions-panel-pane-1,
  .pane-custom-institution-leader-institutions-list,
  .pane-diocesan-messages-dashboard,
  .pane-mentor-profile,
  .pane-institution-list,
  .pane-mooc-dashboard .pane-mooc-dashboard {
    &:extend(.panel, .panel-default);
    .pane-title {
      &:extend(.panel-heading);
      &:extend(.green-pane-subtitle);

    }
    .pane-title[aria-expanded=true] {
      background: #3A6646;
    }
    .pane-content {
      &:extend(.panel-body);
    }

    .views-row {
      margin-bottom: 6px;
    }

    .view-learner-tracks {
      h3 {
        font-size: 18px;
        margin-top: 0;
      }
      .views-row-last {
        margin-bottom: 20px;
      }
      .views-row:last-child {
        margin-bottom: 0;
      }
    }

    // Workshops
    #views-exposed-form-learner-workshops-panel-pane-1 {
      .views-widget-filter-title input {
       width: 210px;
      }
      .views-exposed-widget .btn {
        margin-top: 0;
      }
    }
  }

  #mini-panel-learner_dashboard {
    .workshops-started-pane {
      .ctools-toggle {
        display: none;
      }
      &.ctools-collapsed h2.pane-title:before {
        transform: none;
      }
      h2.pane-title {
        padding-left: 32px !important;
        position: relative;
        &:before {
          content: "\e250";
          position: absolute;
          top: 11px;
          left: 13px;
          display: inline-block;
          font-family: 'Glyphicons Halflings';
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          transition: transform .35s;
          transform: rotate(90deg);
        }
      }
    }
    .workshops-started-pane .ctools-collapsible-content > div {
      transition: all .35s;
    }
  }

  .pane-diocesan-messages-dashboard .pane-content {
    padding: 0;
  }

  .pane-mentor-learners-panel-pane-1 {
    .pane-content {
      padding: 0;
      > div {
        padding: 15px;
      }
    }
  }

  // Learner Workshop Submissions
  .view-mentor-learner-submissions {
    .view-content {
      font-size:  14px;
    }
    table caption {
      text-align:  left;
      color: inherit;
      font-size: 16px;
    }
  }

  // Workshops
  .view-learner-workshops {
    .views-exposed-widgets {
      margin-bottom:  20px;
      label:after {
        content: ":"
      }
    }
    .pager {
      margin: 22px 0 4px;
    }
  }

  // Masquerade Link
  .pane-user-masquerade a {
    &:extend(.btn, .btn-success);
    &:hover, &:focus {
      text-decoration: none;
    }
    .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
  }

  // Track started
  .dashboard-track-completed,
  .dashboard-track-started {
    font-size: 12px;
    font-style: italic;
    color: #8c8c8c;
    display: inline-block;
    padding-left: 3px;
  }
  .dashboard-track-completed {
    color: green;
  }

  // Togglable elements.
  .pane-mentor-profile > h2.pane-title,
  .pane-mentor-learners-panel-pane-1 h3.pane-title,
  .pane-mentor-learner-submissions-panel-pane-1 h3.pane-title,
  .pane-diocese-messages .pane-title,
  .view-accordions .pane-title {
    cursor: pointer;
  }

  // Learner/Mentor Welcome Message section
  .pane-panels-mini.pane-diocesan-messages-dashboard,
  .pane-panels-mini.pane-institutional-leader-dashboard {
    padding-bottom: 7px !important;
  }

  .pane-panels-mini.pane-diocesan-messages-dashboard .panel-pane {
    margin-bottom: 0;
    border: 0;

    h2.pane-title {
      background: #c69f41;
      margin-top: 12px;
    }

    .field-collection-item-field-accordion-items:first-child h2.pane-title {
      margin-top: 0;
    }

    h2.pane-title[aria-expanded=true] {
      background: #a48231;
    }

    .pane-content {
      background: #FFF;
    }

    .panel-body {

      background: rgba(109, 156, 121, 0.1);
    }
  }

  .pane-panels-mini.pane-institutional-leader-dashboard .panel-pane {
    margin-bottom: 22px;
    border: 0;

    h2.pane-title {
      margin-bottom: 12px;
    }

    .pane-content {
      background: #FFF;
    }

    .panel-body {
      background: rgba(109, 156, 121, 0.1);
      h2.pane-title {
        background: #a17f31;
      }

    }
  }

  .view-learner-workshops {
    .workshop-type-WK {
      background:url("/sites/default/themes/subtheme/images/workshop.png") no-repeat;
    }
    .workshop-type-WB {
      background:url("/sites/default/themes/subtheme/images/webinar.png") no-repeat;
    }
    .workshop-type-SM {
      background:url("/sites/default/themes/subtheme/images/seminar.png") no-repeat;
    }
    .workshop-type-indicator {
      background-size: cover;
      display: inline-flex;
      height: 20px;
      width: 28px;
      text-indent: -99999em;
      overflow: hidden;
      justify-content: center;
      align-items: center;
    }

    #workshop-tab-list {
      width: 100%;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .form-item-resource-type {
      li {
        display: inline-flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
      }
      li:before {
        background-size: 100% 100%;
        display: inline-block;
        width: 28px;
        height: 20px;
        content: "";
      }
      li[data-original-index="1"]:before {
        background-image: url("/sites/default/themes/subtheme/images/workshop.png");
      }
      li[data-original-index="2"]:before {
        background-image: url("/sites/default/themes/subtheme/images/webinar.png");
      }
      li[data-original-index="3"]:before {
        background-image: url("/sites/default/themes/subtheme/images/seminar.png");
      }
      li[data-original-index="4"] {
        display: none;
      }
    }

    .prev, .next {
      display: none;
    }
  }

  .lazy-pane-placeholder {
    height: 100vh;
  }

  .pane-diocesan-official-dashboard,
  .pane-institutional-leader-dashboard,
  .pane-mentor-dashboard,
  .pane-learner-dashboard {
    background: white;
    padding: 20px;
    margin-bottom: 45px;
    .panel-title {
      margin: 0;
    }
  }

  .the-catechetical-review {
    text-align: center;
    position: relative;
    img {
      width: 75% !important;
      height: auto !important;
    }
    h2 {
      cursor: help;
      text-align: left;
    }
    #the-catechetical-review-tooltip {
      display: none;
      position: absolute;
      z-index: 50;
      background-color: white;
      color: black;
      padding: 5px;
      border-style: solid;
      border-radius: 6px;
      top: 0;
      left: 100%;
      margin-left: 10px;
      width: 62%;
      text-align: left;
      cursor: help;
    }
    h2:hover + div #the-catechetical-review-tooltip,
    a:hover + #the-catechetical-review-tooltip,
    #the-catechetical-review-tooltip:hover {
      display: block;
    }
    #the-catechetical-review-tooltip::after {
      content: " ";
      position: absolute;
      top: 16px;
      right: 100%;
      margin-top: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent black transparent transparent;
    }
  }
}

.view-learner-workshops .views-exposed-form .form-type-select .btn {
  margin-top: 0;
}

body.dashboard .pane-panels-mini.pane-diocesan-messages-dashboard .panel-pane .panel-body {
  background: #fff;
  margin-bottom: 30px;
}

body.dashboard .pane-panels-mini.pane-diocesan-messages-dashboard h2.pane-title {
  padding-left: 25px;
  position: relative;
  text-transform: none;
  span.glyphicon {
    position: absolute;
    left: 6px;
    top: 11px;
  }
}

body.dashboard .pane-panels-mini.pane-learner-dashboard > .pane-content {
  background: #FFF;
}

body.dashboard .pane-panels-mini.pane-institutional-leader-dashboard .panel-pane.pane-custom-institution-mentors-list h2.pane-title {
  margin-bottom: 0;
}

body.dashboard .pane-panels-mini.pane-diocesan-messages-dashboard .field-collection-item-field-accordion-items h2.pane-title {
  line-height: 1.5;
  background: #669472;
  span.glyphicon {
    top: 14px;
  }
}

body.dashboard .pane-panels-mini.pane-diocesan-messages-dashboard .field-collection-item-field-accordion-items h2.pane-title[aria-expanded=true] {
  background: #3a6646;
}
