.panel {
  box-shadow: none;
  border-radius: 3px;
}


// Vertical spacing between panel regions and panes.
.panel-panel, .panel-pane {
  margin-bottom: @line-height-computed;
}

// Remove bottom margin for last panel region or pane.
.panel-panel:last-child, .panel-pane:last-child {
  margin-bottom: 0;
}

// Remove top margins from pane titles.
.pane-title {
  margin-top: 0;
}

// Panels add content modal.
.panels-add-content-modal {
  margin: -15px;
  border-radius: 0 0 @border-radius-large @border-radius-large;
  padding: 0;
  background: none;

  .panels-section-column-categories {
    margin-left: 0;
    border-right: 1px solid #ccc;
  }

  .panels-section-column {
    width: auto;
    float: none;
  }

  .panels-categories-box {
    border-top: 1px solid #ccc;
    margin: 15px -16px 30px 0;
  }

  .panels-modal-add-category {
    border: 1px solid #ccc;
    font-weight: normal;
    border-top: 0;
    margin: 0;
    padding: 5px 10px;
    background: #eee;
    &.active {
      background-image: none;
      font-weight: bold;
    }
    .ajax-progress {
      float: right;
    }
  }

  .panels-section-columns {
    padding-bottom: 30px;
  }

  .panels-section-columns-quickfilter {
    background: none;
    text-align: right;
    padding: 15px 0 0;
  }

  .content-type-button {
    margin-bottom: 5px;
    padding: 0 15px 10px;
    a {
      white-space: initial;
      text-align: left;
      border: none;
    }
    .content-type-image-title {
      font-size: 15px;
      color: #F5A623;
    }
    .content-type-image {
      display: inline-block;
      width: 25px;
      margin-right: 5px;
    }
    img {
      border: none;
    }
    div, span {
      width: initial;
      top: initial;
      left: initial;
    }
    .content-type-description {
      margin-top: 5px;
      font-size: 12px;
      color: #999999;
    }
  }
}

// Panels "change layout" UI.
.panels-choose-layout {
  // Hide category.
  .panels-layouts-category {
    display: none;
  }

  // Layout links.
  .layout-link {
    float: none;
    width: auto;
    height: auto;
    padding: 0;

    // Better spacing between layouts, with hover color.
    a {
      display: block;
      float: left;
      padding: 1em;
      width: 88px;
      height: 94px;
      text-align: center;
      &:hover {
        background: #ddd;
      }
    }

    // Selected layout.
    &.current-layout a {
      background: #ddd;
    }

    // Grey out images.
    img {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
      filter: gray;
      filter: grayscale(100%);
    }

    // Hide the caption.
    div {
      display: none;
    }
  }
}

// Panels "change layout" confirmation UI.
.change-layout-display {
  margin-bottom: 1em;

  // Grey out images.
  .layout-icon img {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    filter: gray;
    filter: grayscale(100%);
  }

  // Arrow image.
  > img {
    padding: 25px 15px;
  }

  // Hide captions.
  .layout-icon .caption {
    display: none;
  }
}

// Changing layouts in Panels drag-and-drop UI.
.panels-dnd {
  // Clean up vertical spacing.
  margin: 30px 0 !important;

  // Remove layout container padding.
  .panel-display > .container,
  .panel-display > .container-fluid {
    padding: 0;
  }

  .panel-region {
    // Add vertical spacing between regions.
    margin-bottom: 1em;

    // Hide region titles.
    > .label {
      display: none;
    }

    // Since region titles are now hidden, give it a minimum height.
    min-height: 50px;
  }
}
