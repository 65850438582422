// This file contains tweaks to styling added by the Bootstrap theme.
// ----------------------------------------------------------------------------

// Loading spinner added by Bootstrap theme's modal.js.
.loading-spinner {
  margin: 0 auto !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  .progress {
    margin: 0;
  }
}

// Bootstrap theme's user.js moves stuff around on password elements, but for
// some reason misses this straggler label.
.form-type-password-confirm > .control-label {
  display: none;
}

// Tighten up password confirm fields.
.form-type-password-confirm > .help-block {
  margin-top: 0;
}

// Make collapsible fieldset links more obvious.
fieldset .panel-heading a.panel-title {
  text-decoration: underline;
}
