body.page-faq {
  #link-to-tutorials-and-pdfs {
    background:  @tan;
    margin-bottom: 0;
    padding-top: 50px;
    font-size: 18px;
  }
  .pane-page-title h1 {
    padding-bottom: 10px;
    font-family: Allura;
    text-align: center;
    border-bottom: 1px solid black;
  }

  // Left Scrollspy Affix Navigation
  #leftNav {
    top: 50px;
    @media @tablet-only {
      width: 250px;
    }
    @media @normal-only {
      width: 290px;
    }
  }

  // Only affix above mobile
  @media (max-width: 767px) {
    .affix {
        position: static;
    }
  }

  @media (min-width: 768px) {
    .affix {
        position: fixed !important;
    }
  }

  // Questions and Answers
  .block-system h2.pane-title {
    border-bottom: 1px solid #000;
    padding-bottom: 8px;
    margin: 50px 0 10px;
    font-size: 30px;
  }
  .block-system h4 {
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 20px;

  }

  .questions {
    p, ol, li {
      font: 20px/30px "EB Garamond";
      margin-left: 20px;
    }
    p {
      margin-bottom: 20px;
    }
    ol li,
    ul li {
      margin-bottom: 12px;
    }
  }
}
