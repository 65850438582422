// Masquerade pane.
.pane-masquerade-masquerade {
  color: white;
  border:1px solid #FFF;
  max-width: 440px;
  margin:0 auto;
  padding: 16px 12px;
  background-color: rgba(0, 0, 0, 0.2);

  .pane-title {
    font-size: 20px;
    max-width: 420px;
  }

  .pane-content {
    max-width: 420px;
  }

  .description {
    color: white;
    margin-top: 10px;
    font-size: 16px;
    a {
      color: white;
      text-decoration: underline;
    }
  }
}
