.field-name-field-user-image {
  .input-group {
    max-width: 400px;
  }
  .input-group-btn .glyphicon {
    display: none;
  }
}

.field-name-field-user-phone {
  .make-md-column(4);
  padding-left: 0;
}

.field-name-field-user-phone-type {
  .make-md-column(8);
}

.field-name-field-user-diocese {
  margin-bottom: 0;
}

.field-name-field-user-diocese-other {
  label {
    opacity: 0;
  }
}

.field-name-field-user-gender {
  padding-left: 0;
}

.field-name-field-user-gender,
.field-name-field-user-year-of-birth,
.field-name-field-user-ethnicity {
  .make-sm-column(3);
}

.field-name-field-user-ethnicity {
  padding-right: 0;
}

.field-name-field-user-parish {
  clear: left;
}

.field-name-field-parish-name {
  .make-sm-column(6);
  padding-left: 0;
}

.field-name-field-parish-location {
  .make-sm-column(6);
  padding-right: 0;
}

.field-name-field-user-ministry-roles {
  .make-sm-column(6);
  padding-left: 0;
}

.field-name-field-user-experience {
  .make-sm-column(6);
  padding-right: 0;
}

.field-name-field-user-education .form-type-checkbox,
.field-name-field-freecourses-user-reason .form-type-checkbox,
.field-name-field-user-mooc-reason .form-type-checkbox {
  display: inline-block;
  margin-right:  20px;
}

.field-name-field-user-conference {
  .make-sm-column(4);
  padding-left: 0;
}

.field-name-field-user-conference-types {
  .make-sm-column(8);
  padding-right: 0;
}



.field-name-field-user-credit-interest {
  clear: left;;
  .make-sm-column(4);
  padding-left: 0;
}

.field-name-field-user-how-did-hear,
.field-name-field-user-mooc-reason {
  .make-sm-column(8);
  padding-right: 0;
}
