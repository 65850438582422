// This file contains element defaults, plus other misc stuff.
// ----------------------------------------------------------------------------

// Make bold really bold.
b, strong {
  font-weight: 900;
}

// Show row weights
.tabledrag-toggle-weight {
  font-size: 10px;
}

// Convert default pane margins to padding so background color changes work.
.pane-page-content {
  margin: 0;
  padding:15px 0 30px;
}

.pane-page-title {
  margin: 0;
  padding: 50px 0 30px;
  @media @mobile-only {
    padding: 20px 0 16px;
  }
}

.pane-page-tabs,
.pane-page-actions {
  margin: 0;
  padding: 0;
  .pane-content {
    padding-top: 5px;
    &:extend(.container);
  }
}

.pane-page-messages {
  padding: 0;
  margin: 0;
  .messages {
    margin: 15px 0;
  }
}

// Default other background colors to white.
.pane-page-content,
.pane-page-title,
.pane-page-tabs,
.pane-page-messages {
  background-color: #FFF;
}

// On mobile, center align floated images. This works for both images and those
// wrapped in figure elements.
@media @mobile-only {
  .filter-wysiwyg {
    .img-left, .img-right {
      float: none;
      margin: 15px auto;
      text-align: center;
      display: block !important;
    }
  }
}

// Center table captions.
caption {
  text-align: center;
}

// Give some top margin to form action buttons.
.form-actions {
  margin-top: 1em;
}

// Target stuff entered in a WYSIWYG.
.filter-wysiwyg {
  // Remove top padding on first heading element.
  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }

  // Fix list positioning next to floated content.
  // https://basecamp.com/2784215/projects/7844084/todos/231393557
  ul, ol {
    overflow: auto;
  }

  // Fix long links on mobile.
  @media @mobile-only {
    a:not(.btn) {
      word-wrap: break-word;
    }
  }
}

// Change opacity when hovering over linked images.
a img {
  .opacity-hover(.5);
}

// Use smaller left padding for nested lists.
ul ul, ul ol, ol ul, ol ol {
  padding-left: 1em;
}

// Blockquotes.
blockquote {
  margin: @line-height-computed 0;
  padding: 0 15px 0 45px;
  border-left: none;
  color: lighten(@gray-base, 50%);
  font-family: @font-family-serif;
  font-size: (@font-size-base * 1.4);
  position: relative;

  &:after {
    content: '\0201C';
    font-size: 75px;
    line-height: 1em;
    position: absolute;
    top: 0;
    left: 0;
  }

  footer {
    font-family: @font-family-sans-serif;
  }
}

// Add margin beneath DD tags.
dd {
  margin-bottom: 1em;
}

// On dark backgrounds, make the "Skip to main content" link white.
#skip-link a when (lightness(@body-bg) < 50%) {
  color: white;
}

// Responsive audio element.
audio {
  max-width: 100%;
}

// Popovers
.popover-content {
  color: #000;
  font: 13px/17px @sans;
}

// Floats
.float-right {
  float: right;
  img {
    margin: 0 0 14px 14px;
  }
}

.float-left {
  float: left;
  img {
    margin: 0 14px 14px 0;
  }
}

// Coming soon
.coming-soon {
  color: red;
  font-style: italic;
  margin-left: 4px;
  font-size: 80%;
}

// Fixes tabledrag-handle from showing sprite
.draggable a.tabledrag-handle {
  height: initial;
}

// Rotate 90 degrees.
.rotate90 {
  transform: rotate(90deg);
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #4d78e6;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

// New tag for dashboards

.new-tag {
  background-color: white;
  border: 1px solid #337ab7;
  padding: 3px 8px 3px 6px;
  margin-left: 10px;
  font-size: 11px;
  color: #337ab7;
  border-radius: 12px;
  text-shadow: none;
  font-weight: bold;
  position: relative;
  white-space: nowrap;
  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: -11px;
    top: 5px;
    border: 5px solid transparent;
    border-right: 6px solid #337ab7;
    z-index: 2;
  }
  &:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: -9px;
    top: 5px;
    border: 5px solid transparent;
    border-right: 6px solid #fff;
    z-index: 3;
  }
}

.center-main-content #block-system-main {
  max-width: 700px;
  margin: 0 auto;
}

.hide-ipe-elements {
  .panels-ipe-placeholder {
    display: none !important;
  }
  .panels-ipe-linkbar {
    li.style, li.css, li.delete {
      display: none;
    }
  }
  .panels-ipe-draghandle-icon,
  .panels-ipe-change-layout {
    display: none !important;
  }
}
