.view-id-attributions {

  h3.workshop-title {
    margin: 40px 0 20px;
    font-size: 24px;
  }

  .attr-content {
    margin-left: 20px;
    .callout();
  }

}
