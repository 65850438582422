.pane-custom-learner-track-mentor {
  .views-field-field-user-image {
    text-align: center;
    margin: 10px 0 2px 0;

    .field-content img {
      display: inline-block;
      padding-bottom: 12px;
      background: url(images/bg-shadow-sm.png) no-repeat center bottom;
    }
  }
  .views-field-realname {
    font: italic 16px/20px @sans;
    margin-bottom: 10px;
    text-align: center;
  }
  .views-field-field-user-biography {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 16px;
  }
  .views-field-mail {
    margin-bottom: 12px;
  }
}

// When the pane appears in the right sidebar
.subtheme-track-right .pane-custom-learner-track-mentor {
  .help-block {
    font-size: 12px;
    line-height: 13px;
    margin: 0 -4px 10px;
  }
  .field-name-field-user-image {
    padding: 2px;
    .form-control {
      padding: 2px;
      border: 0;
      font-size: 12px;
      box-shadow: none;
      height: inherit;
      margin-bottom: 6px;
    }
    .btn {
      font-size: 11px;
      border-radius: 4px !important;
      margin-left: 1px !important;
      padding: 3px 6px;
    }
    .help-block,
    .focal-point-indicator,
    .focal-point-help {
      display: none;
    }
  }
  .field-name-field-mentored-learner-bio {
    margin-right: -4px;
    textarea {
      font-size:  11px;
      padding: 3px 4px;
      margin-left: -5px;
    }
    .grippie {
      margin-left: -5px;
      margin-right: 5px;
    }
  }
}
