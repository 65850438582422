.pane-page-breadcrumb {
  margin-bottom: 0;
  .breadcrumb {
    border: none;
    font-style: italic;
    font-weight: 300;
    background:rgba(0, 0, 0, 0.05);
    margin: 35px 0 15px 0;
  }
}
