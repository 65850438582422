// Certificate

@font-face {
  font-family: "Allura", cursive;
  //src: url("../fonts/EdwardianScriptITCW01/8502d3ad-0939-412a-afe0-b78e999c88d2.eot?#iefix");
  //src: url("../fonts/EdwardianScriptITCW01/8502d3ad-0939-412a-afe0-b78e999c88d2.eot?#iefix") format("eot"), url("../fonts/EdwardianScriptITCW01/d99f0ff7-9f4b-4d62-9283-7de76d63580e.woff2") format("woff2"), url("../fonts/EdwardianScriptITCW01/e92a529b-97f5-440f-b8a4-a2ad31c621c4.woff") format("woff"), url("../fonts/EdwardianScriptITCW01/2f1f04a2-511d-4a3b-b732-c32c66b26a56.ttf") format("truetype");
}

@font-face {
  font-family: "Calligraphic421W01-Roma";
  src: url("../fonts/Calligraphic421W01-Roma/85a51fb0-45e2-4a6e-8b62-6f3ef1c7ceeb.eot?#iefix");
  src: url("../fonts/Calligraphic421W01-Roma/85a51fb0-45e2-4a6e-8b62-6f3ef1c7ceeb.eot?#iefix") format("eot"), url("../fonts/Calligraphic421W01-Roma/812ddce5-1965-4387-ba09-0e2d40a46e38.woff2") format("woff2"), url("../fonts/Calligraphic421W01-Roma/7d3775c3-28e3-4889-a48c-c7ac5ac5fa52.woff") format("woff"), url("../fonts/Calligraphic421W01-Roma/86715f38-c21a-4dae-9600-46e326c474dd.ttf") format("truetype");
}

.learner-track-progress .track-wrapper {
  .error {
    color: #c40000 !important;
  }

  .email-cert.link {
    color: #337ab7;
    text-decoration: none;
  }

  .email-cert.pointer:hover {
    color: #23527c;
    text-decoration: underline;
  }

  .email-cert.grey {
    color: #aaa;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #337ab7;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  top: 15.8%;
  right: 1.5%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
