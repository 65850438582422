body.page-eic-faq,
body.page-guild.page-eic {
  font-family: "Poppins", sans-serif;
  .pane-page-content {
    background: #FFF;
  }
  h2 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  p {
    line-height: 1.5;
    margin-bottom: 20px;
  }
  #sectionNav ul li a {
    max-width: 360px;
  }
  .questions {
    ol, li, p {
      font: 20px / 30px "EB Garamond";
      margin-left: 20px;
    }
  }
  #intro h1 {
    margin-bottom: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    line-height: 1.2;
    color: #333;
    font-size: 40px;
    margin-bottom: 14px;
  }
  ul.nav:not(.nav-stacked) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin: 20px auto 0;
    a.active {
      color: #333;
      &:hover {
        background: transparent;
        cursor: default;
      }
    }
  }
  #intro h3 {
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    margin-top: 20px;
    color: #333;
    .yearning {
      display: inline-block;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNzEiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNzEgMTEiIGZpbGw9Im5vbmUiPjxwYXRoIGQ9Ik0yIDUuOTJDIDIzLjI2MyAzLjg3MDU4IDg2LjM1OSAwLjM4NjU1OCAxNjguNjM5IDIuODQ1ODciIHN0cm9rZT0iI0YyQzk0QyIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz48cGF0aCBkPSJNMzQuOTY5NyA5LjIxMzMzQzUwLjQ3MTEgNy43NDk0NiA5Ni40NzAyIDUuMjYwODcgMTU2LjQ1NSA3LjAxNzUyIiBzdHJva2U9IiNGMkM5NEMiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+PC9zdmc+);
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: 120px 11px;
      overflow: visible;
      padding-bottom: 7px;
      margin-bottom: -7px;
    }
  }
  #buttons a {
    background: #1FA081;
  }
}




