/**
 * Track and Course Nodes
 */

body.node-type-track,
body.node-type-course {
  div.pane-title {
    .description();
  }
  h2.pane-title {
    font: 900 24px/26px @sans;
    margin-bottom: 10px;
    .light-bottom-border();
  }
  .pane-node-title h1 {
    font: 36px/36px @serif;
    margin-top: 0;
  }

  // Track Description
  .field-name-field-track-description,
  .field-name-field-course-description {
    .callout();
    .field-item {
      h1, h2, h3, h4 {
        margin-top: 0;
      }
    }
  }

  // Start buttons
  #custom-learner-start-course-form .btn,
  #custom-learner-start-track-form .btn {
    &:first-child {
      margin-right: 16px;
    }
    transition: all 300ms;
    &:hover, &:focus {
      transform: translate(5px, 0);
    }
  }

  // Courses of Study
  caption {
    font: 300 italic 15px/18px @sans;
    text-align: left;
    color: #000;
  }
  .table > thead > tr > th {
    margin-bottom: 10px;
    padding-bottom: 0;
    .light-bottom-border();
    &:first-child {
      font: 900 24px/26px @sans;
    }
  }

  tr.track-course-workshop td:first-child {
    max-width: 590px;
  }

  tr.track-course {
    font: 700 18px/20px @sans;
    background: @right-arrow;
    background-size: 20px 20px;
    cursor: pointer;
    &:hover, &:focus {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &.track-course-expanded {
      background: @down-arrow;
      background-size: 20px 20px;
      &:hover, &:focus {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    a,
    span {
      display:inline-block;
      margin-left: 34px;
    }
  }

  tr.track-course-spacer {
    height: 30px;
  }

  // Right Sidebar
  .subtheme-track-right {
    .panel-pane {
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius:  4px;
    }
    h2.pane-title {
      font: 22px/24px @serif;
      text-align: center;
      margin-bottom: 0px;
      padding: 8px 12px;
      background: @light-grey;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    .pane-content {
      background: #F5F5F5;
      border-radius: 0 0 4px 4px;
      padding: 10px 15px;
      font: 14px/18px @sans;
    }
    #edit-field-message-file .panel-title {
      font-size: 14px;
    }
    #edit-field-message-file .panel-heading {
      padding: 2px 12px;
    }
    input.form-file {
      font-size: 12px;
    }
  }
}

/* Categories and Workshop Titles on Track Nodes */
body.node-type-track {
  tr.track-course-category {
    td {
      font: 300 italic 18px/24px @sans;
      padding: 12px 12px 0px 40px !important;
    }
  }
  tr.track-course-workshop {
    td:first-child {
      padding: 4px 8px 4px 50px !important;
    }
    td {
      padding-bottom: 0px !important;
    }
  }
}

/* Categories and Workshop Titles on Course Nodes */
body.node-type-course {
  tr.track-course-category {
    td {
      font: 300 italic 18px/24px @sans;
      padding: 12px 12px 0px 10px !important;
    }
  }
  tr.track-course-workshop {
    td:first-child {
      padding: 4px 8px 4px 20px !important;
    }
    td {
      padding-bottom: 0px !important;
    }
  }
}

/* Workshop Progress */
td.workshop-started {
  color: @in-progress;
  font-style: italic;
}

td.workshop-complete {
  color: @complete;
}

/* Mentor Review Status */

td.workshop-mentor-review- {
  color: #aeaeae;
  font-weight: 300;
}

td.workshop-mentor-review-pending {
  color: @in-progress;
  font-style: italic;
}

td.workshop-mentor-review-approved {
    color: @complete;
}

.messages-wrapper {
  margin-top: 20px;
}