body.page-cancel-automatic-renewal {
  .pane-page-title {
    padding-bottom: 0px;
    h1 {
      font-size: 22px;
    }
  }
}

button#edit-submit,
.green-submit {
  margin-right: 12px;
  &:extend(.btn-success);
  &:hover, &:focus {
    background: #439243;
  }
}

a#edit-cancel {
  &:extend(.btn, .btn-default);
  &:hover, &:focus {
    text-decoration: none;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}
