body.node-type-evaluation {
  p.introduction-to-evaluation {
    font-style:italic;
    font-size: 18px;
  }

  .node-evaluation .webform-client-form .webform-component > label {
    font-size: 18px;
    margin-top: 30px;
  }

  .webform-grid-option {
    text-align: center;
  }

  .webform-component-radios .form-type-radio.radio {
    display: inline-block;
    margin-right: 20px;
  }
}
