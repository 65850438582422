.commerce-add-to-cart .field-name-field-donation-amount {
  label {
    display: block;
  }
  input {
    display: inline-block;
    margin: 0 5px;
    width: 60px;
    text-align: right;
    padding-left: 5px;
    padding-right: 5px;
  }
}
