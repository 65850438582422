.collapse-title {
  cursor: pointer;
}
.collapse-title:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.collapse-title .glyphicon {
  transition: transform .35s;
  transform: rotate(90deg);
}
.collapse-title.collapsed .glyphicon {
  transform: rotate(0);
}
.collapse-inner {
  background-color: #fff;
  padding: 10px;
}

h2.pane-title.ctools-collapsible-handle {
    color:#337ab7;
}

.ctools-collapsible-content {
    padding: 10px 0 20px 20px;
}
