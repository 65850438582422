.team-category {
  font: 700 18px/20px @sans;
  background: @right-arrow;
  background-size: 20px 20px;
  cursor: pointer;

  &:hover, &:focus {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.track-course-expanded {
    background: @down-arrow;
    background-size: 20px 20px;

    &:hover, &:focus {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  a,
  span {
    display: inline-block;
    margin-left: 34px;
  }
}

body.page-team {
  .pane-page-title h1 {
    text-align: center;
    padding-bottom: 55px;
    background: url(images/accent.png) no-repeat center bottom;
    background-size: 175px 31px;
    font-family: "EB Garamond", Georgia, "Times New Roman", Times, serif;
    font-size: 36px;
    line-height: 36px;
  }
  .panel-pane.intro {
    max-width: 760px;
    padding: 32px;
    background: rgba(0, 0, 0, 0.04);
    margin: 0 auto 50px;
  }
  h2 {
    font-size: 28px;
    font-family: "EB Garamond", Georgia, "Times New Roman", Times, serif;
    @media @mobile-only {
      font-size: 20px;
    }
  }
  .views-row {
    h4 {
      margin-top: 0;
      margin-bottom: 4px;
    }
    .title {
      font-style: italic;
      font-size: 14px;
    }
    .org {
      font-weight: bold;
      font-size: 14px;
    }
    .teaser {
      font-size: 13px;
      margin-top: 8px;
    }
    img {
      max-width: 120px;
    }
    .panel-body:hover {
      cursor: pointer;
      background: rgba(210, 162, 37, .06);
      box-shadow:0 0 14px rgba(210, 162, 37, .4);
    }
  }

  .ctools-collapsible-container.ctools-collapsed h2 {
    color: #337ab7;
  }
  .ctools-collapsible-container {
    margin-bottom: 30px;
  }
  .view-content > div {
    margin: 12px 0;
  }
  @media (min-width:1200px) {
    .view-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        width: 48%;
      }
    }
  }
}
