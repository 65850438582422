// Patron Saint
.pane-workshop-patron-saint-panel-pane-1 {
  margin-bottom: 30px;
  .pane-content {
    padding: 20px;
  }
  .views-field-field-patron-saint-image {
    margin-top: 10px;
    text-align: center;
    .field-content img {
      display: inline-block;
      padding-bottom: 9px;
      background: url(images/bg-shadow-sm.png) no-repeat center bottom;
      background-size: 100px 15px;
    }
  }
  .title {
    margin-top: 5px;
    font: 16px/20px @sans;
  }
  .pray-for-us {
    font: 14px/14px @sans;
    margin-bottom: 10px;
  }
}
