// Coupon form.
#uc-cart-pane-coupon {
  padding-bottom: 1em;
}

// Checkout form.
#uc-cart-checkout-form {

  // Add spacing between coupon sections.
  #uc-coupon-active-coupons, #coupon-messages {
    margin-top: 1em;
  }

  // Add spacing between payment options.
  .form-item-panes-payment-payment-method {
    margin-bottom: 1em;
  }

}
