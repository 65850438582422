// Logintoboggan unified login/register form.
.toboggan-unified {
  // Hide top message. See custom_core_page_alter().
  #login-message {
    display: none;
  }

  // Style buttons.
  #login-links {
    margin-bottom: @line-height-computed;
    a {
      .btn();
      .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
      &.lt-active {
        &:extend(.btn.active);
        .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
        opacity: 0.6;
      }
    }
  }

  // Hide forms initially.
  #register-form, #login-form {
    display: none;
  }
}
