#branding {
  background-image: radial-gradient(50% 104%, #457654 46%, #21412A 100%);
  margin-bottom: 0;
  box-shadow: 0px 3px 12px #000;
  position: relative;
  z-index: 1;

  .pane-content {
    padding: 20px;
    @media @tablet {
      padding: 55px 20px;
    }
    text-shadow: 1px 1px 4px #000;
    text-align: center;
    color: #FFF;

    /* Franciscan at Home */
    h1 {
      margin: 0;
      a {
        color: #FFF;
        text-transform: uppercase;
        transition: 300ms all;
        &:hover, &:focus {
          text-decoration: none;
          opacity: 0.5;
          transform:scale(1.1, 1.1);
          display:inline-block;
        }
      }
      font: 35px/35px @serif;
      span {
        font: 55px/55px @serif;
      }
      @media (max-width: 767px) {
        font: 25px/30px @serif;
        span {
          font: 35px/35px @serif;
        }
      }
    }
    
    div.slogan {
      margin-top: 10px;
      font: 40px/40px @script;
      @media (max-width: 767px) {
        font: 25px/30px @script;
      }
    }

  }
}

/* Don't make the site name look like a link on the front page */
body.front #branding .pane-content h1 a {
  &:hover, &:focus {
    opacity: 1;
    cursor: default;
    transform:scale(1, 1);
  }
}

/* Remove margins for page content on front page */
body.front .pane-page-content {
  padding: 0;
}
