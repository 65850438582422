.view-mooc-offerings,
.view-mooc-opportunities {
  .views-field-nothing a {
    display: block;
    transition: all 300ms;
  }

  a {
    color: #333;

    &:hover, &:focus {
      color: #333;
      opacity: 0.9;
      transform: translateY(-6px);
    }

    img {
      &:hover {
        opacity: 1;
      }
    }

    .wrapper {
      position: relative;
      max-width: 330px;
      margin: 0 auto;

      .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: left;
        padding: 20px;
        background: rgba(255, 255, 255, 0.8);
        font-size: 14px;
      }

      .title {
        font-size: 18px;
        margin-bottom: 6px;
        font-weight: bold;
      }

      @media @mobile-only {
        .title {
          font-size: 16px;
          margin-bottom: 2px;
        }
        .mooc_opp_description {
          display: none;
        }
      }
    }
  }

  .owl-carousel {
    padding: 0 50px;
    overflow: hidden;

    .owl-nav button {
      width: 30px;
      height: 20px;

      &:hover {
        opacity: 0.5;
      }

      &.owl-prev, &.owl-prev:hover {
        position: absolute;
        left: 0;
        top: 40%;
        text-indent: -1000px;
        background: url('/sites/default/themes/subtheme/images/mooc-left.png') no-repeat;
        background-size: 30px 20px;
      }

      &.owl-next, &.owl-next:hover {
        position: absolute;
        right: 0;
        top: 40%;
        text-indent: -1000px;
        background: url('/sites/default/themes/subtheme/images/mooc-right.png') no-repeat;
        background-size: 30px 20px;
      }
    }
  }
}

.overlay-container {

  position: relative;

  > .overlay-img {
    width: 100%;
  }

  > .overlay-text {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px;
    width: 100%;

    &.simple {
      text-align: center;
      font-weight: bold;
      font-size: 1.1em;
    }

    &.complex {
      text-align: left;
      line-height: 1.2;

      p {
        padding-top: 3px;
        font-size: 0.8em;
      }
    }
  }
}

.flex-container {
  display: flex;

  > .flex-img {
    overflow: hidden;
    flex-shrink: 0;
  }

  > .flex-body {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 20px;
    text-align: left;
    width: 100%;
  }
}

.view-mooc-offerings.view-display-id-panel_pane_1 {
  max-height: 80vh;
  overflow-y: scroll;

  .flex-container {
    margin-bottom: 15px;
  }

  .flex-img {
    width: 20%;

    img.img-responsive {
      width: 100%;
    }
  }
}

.view-mooc-opportunities.view-display-id-block,
.view-mooc-offerings.view-display-id-panel_pane_2 {

  .view-content {
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .views-row {
    padding: 5px;
  }

  .pager {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .pager-current {
    display: none;
  }

  .pager-previous,
  .pager-next {
    position: absolute;
  }

  .pager-previous {
    left: 0;
  }

  .pager-next {
    right: 0;
  }

  .view-footer {
    margin-top: 40px;
  }
}

.pane-mooc-opportunities {
  h2 {
    color: @gold;
  }

  .btn {
    margin: 15px auto 0;
  }
}