// Vertical spacing between portlet wrapper to match panel regions and panes.
.panels-ipe-portlet-wrapper #panels-ipe-paneid-19{
  margin-bottom: @line-height-computed;
}

// Remove bottom margin for last portlet wrapper, and editing region.
.panels-ipe-portlet-wrapper:last-child,
.panels-ipe-editing .panels-ipe-region {
  margin-bottom: 0;
}

// Throbber which appears after clicking "Edit content" or "Change layout".
.ipe-throbber {
  background-image: none;
  color: white;
  font-size: 14px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  line-height: 30px;
  height: 28px;
  width: 28px;
}

// Hide Panels IPE pane titles.
.panels-ipe-dragbar-admin-title {
  display: none;
}

// Hide Contextual Links triggers when IPE is active.
body.panels-ipe-editing .contextual-links-trigger {
  display: none;
}

// Helper classes to toggle visibility of stuff based on whether or not IPE is
// active. Similar to Bootstrap's responsive utility classes.
.visible-ipe-block, .visible-ipe-inline-block, .visible-ipe-inline {
  display: none;
}
body.panels-ipe-editing {
  .hidden-ipe {
    display: none;
  }
  .visible-ipe-block {
    display: block;
  }
  .visible-ipe-inline-block {
    display: inline-block;
  }
  .visible-ipe-inline {
    display: inline;
  }
}
