// Webform submission info header thing.
.webform-submission-info {
  padding: 0;
  margin-bottom: 1em;
}

// Let webform inputs use the element's native width for inputs and selects,
// but not textareas (because of the Grippie which always uses 100% width), and
// and not file fields.
.webform-component {
  > input.form-control:not(.form-file), select.form-control {
    width: inherit;
  }
}

// Date component.
.webform-component-date .webform-container-inline {
  .form-item {
    display: inline-block;
    margin-right: .5em;
  }
  .webform-calendar {
    padding-top: 10px;
  }
}

// Time component.
.webform-component-time .webform-container-inline {
  .form-item {
    display: inline-block;
  }
  select.hour {
    margin-right: .25em;
  }
  select.minute {
    margin-left: .25em;
  }
  .form-radios {
    display: inline-block;
    margin-left: 1em;
  }
}

// Fix inline webform containers.
.webform-container-inline {
  .form-inline > & {
    display: inline-block;
  }

  .form-item {
    margin-bottom: 0;
  }
}

// Add vertical spacing beneath file component.
.webform-component-file .description {
  margin: 5px 0 15px;
}

// Style 'This task was previously sent to your mentor.' message on workshop components
span.previously-sent-to-mentor {
  font-style: italic;
  padding-left: 10px;
  color: green;
}
