body.page-about-old {

  .panel-pane.pane-page-content {
    padding-top: 50px;
    font-size:  15px;
  }

  #about-intro {
    padding: 90px 10px 90px 390px;
    background: url(/sites/default/themes/subtheme/images/about-bg.jpg) no-repeat top left;
    @media (max-width:991px) {
      padding: 0px;
      background-image: none;
    }
    font-size: 17px;
  }

  #pdf-downloads,
  .about-events {
    background: rgba(156, 133, 71, 0.08);
    padding: 35px;
    border-radius: 5px;
    h2, h4 {
      margin-top: 0;
    }
    ul {
      margin-bottom: 20px;
      li{
         list-style: none;
         margin-bottom: 4px;
         &:before{
            content: '';
            display: inline-block;
            height: 11.27px;
            width: 23px;
            background-image: url(/sites/default/themes/subtheme/images/pdf.png);
            background-size: 23px 11.27px;
            margin-right: 5px;
         }
      }
    }
  }

  #three-links {
    .pane-content {
      font-size: 16px;
      padding-top: 12px;
      display: flex;
      justify-content: space-around;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      a {
        transition: all 300ms;
        transform: scale(1,1);
        &:hover {
          transform: scale(1.1, 1.1) translateY(-10px);
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
        }
        display: block;
          width: 150px;
        @media (min-width: 992px) {
          width: 200px;
        }
        color: #FFF;
        padding: 7px 14px 20px;
        border-radius: 5px;
        transition: all 300ms;
        padding-top: 80px;
        @media (min-width: 992px) {
          padding-top: 110px;
        }
        text-align: center;
        &:hover {
          text-decoration: none;
          background: #9C8547;
        }
        &#faq {
          background: url('/sites/default/themes/subtheme/images/faq.png') center 20px no-repeat #337ab7;
          background-size: 65px 50px;
          @media (min-width: 992px) {
            background-size: 100px 75px;
          }
        }
        &#resources {
          background: url('/sites/default/themes/subtheme/images/resources.png') center 20px no-repeat #337ab7;
          background-size: 64px 50px;
          @media (min-width: 992px) {
            background-size: 96px 75px;
          }
        }
        &#team {
          background: url('/sites/default/themes/subtheme/images/team.png') center 20px no-repeat #337ab7;
          background-size: 77px 50px;
          @media (min-width: 992px) {
            background-size: 115px 75px;
          }
        }
      }
    }
  }

  #collaborators {
    h2.pane-title {
      margin-bottom: 22px;
    }
    .view-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .views-row {
        display:  flex;
        align-items: center;
        background: #FFF;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
        margin-bottom: 20px;
        padding: 10px 20px;
        height: 80px;
        position: relative;
        border-radius: 5px;
        flex: 0 100%;
        @media (min-width: 992px) {
          flex: 0 48%;
        }
        transition: all 300ms;
        &:hover {
          transform: translateX(10px);
        }
        @media (min-width: 992px) {
          &:nth-child(odd) {
            margin-right: 2%;
          }
        }
        img {
          max-width: 120px;
          max-height: 50px;
          width: auto;
        }
        .views-field-field-collaborator-image {
          min-width: 120px;
          text-align: center;
          margin-right: 30px;
        }
        .views-field-title {
          font-size: 15px;
          a {
            color: #444;
            &:after {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 1;
              pointer-events: auto;
              content: "";
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  h2.pane-title {
    font-size: 18px;
    margin-bottom: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #c3c3c3;
  }

  .view-learner-workshops {
    font-size: 12px;
    .views-row {
      padding: 4px 0;
    }
  }

  .view-participating-dioceses {
    .views-field-field-diocese-image {
      width: 27px;
      margin-right: 12px;
      img {
        width: 17px;
      }
    }
    .partnering {
      display: none;
    }
    .views-field-title {
      display: flex;
      align-content: center;
    }
    .views-row {
      font-size: 13px;
      line-height: 13px;
      display: flex;
      align-items: center;
      min-height: 35px;
    }
  }
}
