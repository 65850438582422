// This file contains helper classes that can be added to HTML for quick and
// easy styling.
// ----------------------------------------------------------------------------

// Margin and padding *********************************************************

.margin-padding(xs, @margin-padding-sizes);

@media @tablet {
  .margin-padding(sm, @margin-padding-sizes);
}

@media @normal {
  .margin-padding(md, @margin-padding-sizes);
}

@media @wide {
  .margin-padding(lg, @margin-padding-sizes);
}

// Backgrounds ****************************************************************

.bg-cover {
  background-size: cover;
  background-position: center;
}

.bg-tint {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.bg-tint-white {
  &:before {
    background-color: fade(#fff, 90%);
  }
}

// Fonts **********************************************************************

.font-sans-serif {
  font-family: @font-family-sans-serif;
}

.font-serif {
  font-family: @font-family-serif;
}

.font-script {
  font-family: @font-family-script;
}

// Text ***********************************************************************

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

// Between normal text and 'lead'.
.bigger {
  margin-bottom: @line-height-computed;
  font-size: floor((@font-size-base * 1.1));

  @media (min-width: @screen-sm-min) {
    font-size: (@font-size-base * 1.25);
  }
}

// Colors *********************************************************************

.colors(@helper-colors);

.color-inherit {
  color: inherit;
}

// Links **********************************************************************

.links-underline a:not(.btn), .link-underline {
  text-decoration: underline;
}

// Borders ********************************************************************

.border-top {
  border-top: 1px solid @hr-border;
}

.border-bottom {
  border-bottom: 1px solid @hr-border;
}

.border-thick {
  border-width: 2px;
}

.border-thicker {
  border-width: 10px;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

// Buttons ********************************************************************

// .btn-blue {
//   .button-variant(#00f; #00f; #00f);
// }

// Forms **********************************************************************

// Add to form fields to use native field width.
.form-control-auto {
  width: auto;
  max-width: 100%;
}

// Tables *********************************************************************

.table-auto {
  width: auto;
}

.table-sm {
  font-size: 13px;
}

.table-style1 {
  th {
    background: #888888;
    border: 1px solid #ababab;
    color: #FFF;
    padding-left: 8px !important;
    border-bottom: 0 !important;
    font-weight: normal;
  }
  td {
    &:first-child {
      border-left: 1px solid #CCC;
    }
    &:last-child {
      border-right: 1px solid #CCC;
    }
    padding-left: 8px !important;
  }
  tr:last-child td {
    border-bottom: 1px solid #CCC;
  }
}

.table-style2 > tbody > tr:not(.collapsed) {
  background: rgba(0, 0, 0, 0.05);
}

// Images *********************************************************************

.img-right {
  float: right;
  margin: 0 0 15px 15px;
}

.img-left {
  float: left;
  margin: 0 15px 15px 0;
}

.img-center {
  text-align: center;
  margin-bottom: 15px;
}

.img-captioned {
  figcaption {
    color: @text-muted;
    font-size: .9em;
    margin-top: 5px;
  }
}

// Lists **********************************************************************

// Centered list blocks.
.list-centered {
  text-align: center;
  ul, ol {
    display: inline-block;
    li {
      text-align: left;
    }
  }
}

// Misc ***********************************************************************

.completed {
  color: #5CB85C;
  &:before {
    content: '✓';
    display: inline-block;
    margin-right: 4px;
  }
}

// Container parent (direct DIV ancestors will be container'd).
.container-parent > .pane-title,
.container-parent > div {
  &:extend(.container);
}

// Makes content with view content resemble view pane title.
.reverse {
  margin: -15px -15px 15px -15px !important;
}

// Hide and image and reveal image
.item-hidden {
  display: none;
  opacity: 0;
  transition: all 300ms;
}

.item-show {
  display: inline-block;
  opacity: 1;
}

// Hide This
.hide-this {
  display: none;
}

// Copy to clipboard animation
.copied::after {
  position: absolute;
  top: 0;
  right: 5px;
  display: block;
  content: "Copied";
  font-size: 0.75em;
  padding: 3px 5px;
  color: #fff;
  background-color: #24bb2e;
  border-radius: 3px;
  opacity: 0;
  will-change: opacity, transform;
  animation: showcopied 1.5s ease;
}

@keyframes showcopied {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 1;
    transform: translateY(-150%);
  }
  100% {
    opacity: 0;
    transform: translateY(-150%);
  }
}
