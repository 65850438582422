.bootstrap-select > .dropdown-toggle {
  width: initial;
}

.bootstrap-select.btn-group .dropdown-menu {
  min-width: initial;
}

.views-exposed-form .views-exposed-widget .btn.dropdown-toggle {
  margin-top: 0;
}

.form-control {
  width: initial;
  &.bef-select-as-checkboxes {
    border: none;
    background: transparent;
    box-shadow: none;
    input.form-control {
      display: inline-block;
      box-shadow: none;
      height: inherit;
    }
    label {
      font-weight: normal;
    }
  }
}

.bef-select-as-radios {
  .form-item {
    font-size: 16px;
    line-height: 1.42857143;
    color: #555555;
    display: block;
    height: 30px;
    padding: 2px 12px;
  }
}

.form-item-file button {
  white-space: nowrap;
}

.bootstrap-select {
  box-shadow: none;
}

.focal-point-help {
  &:extend(.help-block);
  max-width: 365px;
}

table.field-multiple-table .form-item {
    width: 70%;
    float: left;
    margin-right: 10px;
}

.page-user-edit .focal-point-preview-link,
.page-freecourses-user-edit .focal-point-preview-link,
.page-user-mooc-edit .focal-point-preview-link {
  display:none;
}

.multiple-fields-remove-button {
  margin-top: 1px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 3px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  &:hover, &:focus, &:active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}
