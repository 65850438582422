.pane-panels-mini.pane-diocesan-official-dashboard {

  > .pane-title {
    &:extend(.green-pane-title);
 }

 .pane-custom-diocese-official-dioceses-list {
   font-size: 14px;
 }

 h4 {
   font-size: 18px;
   font-weight: bold;
 }

  .view-institution-subscriptions-in-diocese {
    &:extend(.panel, .panel-default);
    .view-content {
      &:extend(.panel-body);
    }
    caption {
      color: #333;
      font-size: 18px;
      line-height: 22px;
      text-align: left;
    }
  }

  .pane-custom-diocese-learners-list,
  .pane-custom-diocese-mentors-list {
    &:extend(.panel, .panel-default);

    h2.pane-title {
      &:extend(.panel-heading);
      &:extend(.green-pane-subtitle);
      cursor: pointer;
    }
    h2.pane-title[aria-expanded=true] {
      background: #3A6646;
    }
    .pane-content {
      &:extend(.green-light-bg);
      table {
        background-color: #FFF;
      }
    }
  }

  .pane-custom-diocese-official-dioceses-list {
    h2.pane-title {
      &:extend(.panel-heading);
      &:extend(.green-pane-subtitle);
      cursor: pointer;
    }
    h2.pane-title[aria-expanded=true] {
      background: #3A6646;
    }
  }

  table {
    margin-bottom: 0;
  }
}

// Learners needing a mentor.
.pane-custom-mentor-assign {
  .pane-content {
    &:extend(.green-light-bg);
    table {
      background-color: #FFF;
    }
  }
}

// Center align mentor select widget.
// For some reason it wasn't centering correctly. This fixes it.
.pane-custom-mentor-assign .form-item.form-type-select.form-group > div {
  display: flex;
  align-items: center;
}
