// Page site name.
.pane-page-site-name {
  font-size: 40px;
  line-height: 1.3em;
  text-align: center;
  margin: 15px 0;

  // It may be linked, but use the inherited color.
  a {
    color: inherit;
  }
}
