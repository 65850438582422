/* Keep disable hover background-color change for disabled active buttons */
.btn-default.active[disabled]:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 2px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-xs, .btn-group-xs > .btn, .btn-group-xs > .btn {
  padding: 1px 4px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

.bootstrap-select > .dropdown-toggle {
    padding-right: 23px;
}

.btn-gold {
  color: @gold-btn-text;
  background-color: @gold-btn;
  border-color: @gold-btn-border;
}
.btn-gold:focus,
.btn-gold.focus {
  color: @gold-btn-text;
  background-color: @gold-btn-focus;
  border-color: @gold-btn-focus-border;
}
.btn-gold:hover {
  color: @gold-btn-text;
  background-color: @gold-btn-hover;
  border-color: @gold-btn-hover-border;
}
.btn-gold:active,
.btn-gold.active,
.open > .dropdown-toggle.btn-gold {
  color: @gold-btn-text;
  background-color: @gold-btn-active;
  border-color: @gold-btn-active-border;
}
.btn-gold:active:hover,
.btn-gold.active:hover,
.open > .dropdown-toggle.btn-gold:hover,
.btn-gold:active:focus,
.btn-gold.active:focus,
.open > .dropdown-toggle.btn-gold:focus,
.btn-gold:active.focus,
.btn-gold.active.focus,
.open > .dropdown-toggle.btn-gold.focus {
  color: @gold-btn-text;
  background-color: @gold-btn-active-hover;
  border-color: @gold-btn-active-hover-border;
}
.btn-gold:active,
.btn-gold.active,
.open > .dropdown-toggle.btn-gold {
  background-image: none;
}
.btn-gold.disabled:hover,
.btn-gold[disabled]:hover,
fieldset[disabled] .btn-gold:hover,
.btn-gold.disabled:focus,
.btn-gold[disabled]:focus,
fieldset[disabled] .btn-gold:focus,
.btn-gold.disabled.focus,
.btn-gold[disabled].focus,
fieldset[disabled] .btn-gold.focus {
  background-color: @gold-btn;
  border-color: @gold-btn-border;
}
.btn-gold .badge {
  color: @gold-btn-text;
  background-color: @gold-btn-text;
}