/**
 * MOOC Course & MOOC Session Nodes
 */

body.node-type-mooc-course,
body.node-type-mooc-session,
body.node-type-mooc-quiz {

  @media @mobile-only {
    .subtheme-mooc-session-right {
      margin-top: 50px;
    }
  }

  .pane-node-field-mooc-course-description,
  .pane-node-field-mooc-session-description,
  .pane-node-field-mooc-quiz-description {
    .callout();
  }

  .popover {
    max-width: 370px;
  }

  div.pane-title {
    .description();
  }

  .pane-node-title h1 {
    font: 46px/46px @script;
    margin-top: 0;
    margin-bottom: 0;
    color: @red;
  }

  // Workshop content.
  .pane-page-content {
    padding-top: 35px;

    // Handout button links
    .webform-component--handout a.btn {
      transition: all 300ms;

      &:hover, &:focus {
        transform: translate(5px, 0);
      }
    }

  }

  // Right Sidebar
  .subtheme-mooc-course-right,
  .subtheme-mooc-session-right,
  .subtheme-mooc-quiz-right {
    .panel-pane {
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      &.pane-jump {
        border: none;
      }
    }

    h2.pane-title {
      font: 22px/24px @serif;
      text-align: center;
      margin-bottom: 0px;
      padding: 8px 12px;
      background: @light-grey;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .pane-content {
      h3 {
        font-size: 15px;
        margin: 10px 0 6px 0;
        font-weight: bold;
      }

      a.active {
        color: #333;
        text-decoration: none;
        cursor: default;
        font-weight: bold;
      }

      background: #F5F5F5;
      border-radius: 0 0 4px 4px;
      padding: 10px 15px 18px;
      font: 14px/18px @sans;

      .nav > li > a {
        position: relative;
        display: block;
        padding: 6px 12px 6px 24px;
        margin-left: -15px;
        font: 14px/16px @sans;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAASBAMAAACQmVJ4AAAABGdBTUEAALGPC/xhBQAAABJQTFRFAAAALi4uKCgoJiYmJycnJiYmrzMkLAAAAAV0Uk5TABYgreA092jOAAAAKElEQVQI12NgAANlBRBp6gQiVUJAHCZX3BxHOAkWgciiM6GmQUwGAwC9Cwg5Pb+1xQAAAABJRU5ErkJggg==') no-repeat 12px center;
        background-size: 5px 10px;
        transition: all 200ms;

        &:hover, &:focus {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }

      .modal-body {
        font-size: 15px;
        line-height: 21px;
      }
    }

    // Patron Saint
    .views-field-field-patron-saint-image a {
      cursor: pointer;
    }
  }

  // The Webform
  .pane-node-webform,
  .pane-mooc-course-register {
    .webform-component {
      margin-bottom: 50px;

      > label {
        color: @gold;
        line-height: 1.3em;

        &.custom-workshop-label-heading {
          font-size: 26px;
          width: 100%;
          border-bottom: 2px solid @gold;
          margin-bottom: 12px;
        }

        &.custom-workshop-label-subheading {
          font-size: 18px;
        }
      }

      p {
        font: 16px/24px @sans;
        margin-bottom: 16px;
      }

      .form-control {
        background-color: rgba(0, 0, 0, 0.03);
      }

      table td {
        padding: 10px;
      }

      &.answer {
        margin-top: -35px;

        > label {
          color: unset;
        }
      }
    }
  }

  // Tame file upload UI
  .form-type-managed-file {
    .input-group {
      display: inline-block;
    }

    .form-control {
      width: initial;
    }
  }

  // Form Item Explanations
  .form-item-explanation {
    font: italic 14px/16px 'Open Sans';
    color: #777
  }

  .mooc-course-correct-choice .form-item-explanation,
  .mooc-session-correct-choice .form-item-explanation,
  .mooc-quiz-correct-choice .form-item-explanation {
    color: green;
    background: #e6f3e6;
    font-weight: bold;
    padding: 10px;
  }

  // Jump Button
  .pane-jump .pane-content {
    background: none !important;
  }

  a#jump-button {
    display: block;
    width: 38px;
    height: 32px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABICAMAAABfoSITAAAABGdBTUEAALGPC/xhBQAAAQhQTFRFAAAA////mZmZqqqqjIyMjo6OjY2NjY2Njo6OjY2NjIyMjIyMjY2NjY2NjY2NjIyMjY2NjY2NjY2Nl5eXmJiYjIyMjY2Njo6Oj4+PkJCQkZGRk5OTlZWVlpaWl5eXmpqanJycnZ2dn5+foKCgo6Ojp6enqKioq6urrq6usLCws7OztLS0tbW1uLi4ubm5urq6u7u7vb29vr6+wMDAw8PDxMTEyMjIzc3N0NDQ0tLS09PT19fX29vb3Nzc4ODg4eHh4uLi5OTk5eXl5+fn6Ojo6+vr7Ozs7u7u7+/v8PDw8fHx8vLy9PT09fX19vb29/f3+Pj4+fn5+vr6+/v7/Pz8/f39/v7+////fumnBwAAABV0Uk5TAAIFBlBRU1VWiYqMpabZ3N3z/v7+t9YfFgAAAiBJREFUWMPtmNlWwkAMhgdQFtnVCgqIKIg7ICgoaoG6K4oV5P3fxPxFFNEeaBtvPM3FdCYJHzNJYHoiBMTpCYSikkWJhoJel/iU2bDEJBH3B9LhkxjF79CgcxKr+LWzYzZfUNS+RVGVwjxYFAEn4rkg91mksYi4uoQH+2RiEhV79YoAjYU+mxQJFxQhGhU+qEK4kEDNq3xQFb8CgXz1GQU8G2pDbeg/grZa/NC7VOqOG/qckaTMMy+0uwm/zS4rdHdw/e5xQo+Gl/oRH/QUTuvrGE+5oHKcfFL39yl6xGUe6HWCXFboxlVWaJK45oC20+SxdIbp2RJN023r0NcsPKqDRRXz7Ktl6DYcSsNVCattq9BD2Lfehsu3LawPrUEH5x15g1FHo2EOeq5l5nFU9ajl7dw89FKroavvyiutwi7NQh9WyRa7GFdfxEi9+mAO+rIB28lPwwn0Gy9moL08TJXfTBVY8j0T0H1Ydn7fzg5s+8ahxzDkdP6VuzlYj41C6yimzJNejp8yKKy6MWhzmdTJW/0av02Sw3LTCPRG7yPjX3szPbSzpne48QCtdabfaVkvDeOpLBuJaS12MPniPIjVjGW/1ZsM7bXsV0kbakNt6Cj0Txozf9JCCtJY5IMWCBcQXrTlGlxMGW05j3BF6LHIRJUXCBZ2CuHmbnXOooHq523K+gbtY1aqz/HRlXZHuJDhma/2ucsb5GjJBzxODfcOC7yG0hid6V8AAAAASUVORK5CYII=') no-repeat center;
    background-size: 38px 32px;
    position: fixed;
    bottom: 20px;
    right: 20px;

    &:hover {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABICAMAAABfoSITAAAABGdBTUEAALGPC/xhBQAAAPlQTFRFAAAA////mZmZqqqqjIyMjo6OjY2NjY2Njo6OjY2NjIyMjIyMjY2NjY2NjY2NjIyMjY2NjY2NjY2NjIyMjY2Njo6Oj4+PkJCQkZGRkpKSk5OTlJSUlZWVlpaWl5eXmZmZmpqam5ubnJycnZ2dn5+foKCgo6OjpKSkpqamp6enqqqqq6urr6+vsLCwtLS0uLi4ubm5u7u7vr6+w8PDx8fHyMjIy8vLzc3Nzs7O0tLS09PT1tbW19fX2NjY29vb3d3d4ODg4+Pj5OTk6Ojo6+vr7Ozs7u7u7+/v8fHx9PT09fX19vb2+Pj4+vr6+/v7/Pz8/f39/v7+/////Rc+EwAAABN0Uk5TAAIFBlBRU1VWiYqMpabZ3N3z/k2fFsEAAAHxSURBVFjD7dhZU8IwEADg0AoFaQs1AgoqqIh4ACrIpRyKohY57P//MWZTlKqAtl1enO4D5CAfZJPpMEsIhCDJqkZdhqYqQZF8hj9CkSIamJK+EEWMsI+j6xQ1wnzv0NrAATnDMiBE8EwTiopEwjRNKkhkih4KUfFRlWj4qEboCsJDPdRD/xGaz+Oj+7q+j42m+obRT+Gi8TuDxV0cFb01eNxgohVjGhU8tATe4yO8lrDQ0zHT9ExGZ2/jUxw0+8qw4TGlx0PWeM1ioLvPjHorQLPwxprPu+7RRA9SeWV2rqDdS7hG2+DUP3p16LXdojVQOpsf3c0O9GvuUHO/W7OBLWs2nKHn/GT2rEN7/NzOnaM5foeOvg4e8RuWc4qmX9jyydn34bMJG35JO0O3HyB9Fz8nLmD8YdsJGuvC2ut5U9cw0405QJuwsjV/rgVzTftoGdbdL3gqx+9htmwXLcJl6u8sWrbTh4tVtIeejNiiwcHiszgYsA+MTuygh8uWWL/28O9o8mnZ5qwJekr+/ZdWlx2D9SirdnJ6OWn8/oBvTC7tnX4+9jsay3t/JT3UQz3Uiq6kMLOSEpKCj8okSNHLchIRoxS5gBgRCAlQilvq9EMBNYyb0ZBZPkZVQ75pVToQxSIja7PyuRhUMErysiRw7h3v5/aXWeTymQAAAABJRU5ErkJggg==');
    }
  }

  .webform-client-form .form-actions {
    text-align: center;
  }

  .custom-workshop-short-response-inner .glyphicon-print,
  .field-name-field-mooc-course-survey article > header {
    display: none;
  }

  // Survey
  .field-name-field-mooc-course-survey {
    form {
      background: #FFF;
      padding: 30px;
      border: 1px solid #ccc;
      border-radius: 5px;
      max-width: 570px;
      margin: 50px 0;
    }
    h3 {
      font-size: 22px;
      padding: 16px;
      background: #eee;
      border-bottom: 1px solid #CCC;
      margin: -30px -30px 20px -30px;
    }
    label[for=edit-submitted-the-length-of-this-course-was-answer] {
      display: none;
    }
    .form-actions {
      padding-top: 14px;
      text-align: left !important;
    }
  }
}
