// Add margins so it matches other fields in addressfield form fields.
form div.name-block, form div.street-block, form div.locality-block {
  .form-item {
    margin-bottom: @form-group-margin-bottom;
  }
}

// Then remove padding at bottom of addressfield panel body.
#addressfield-wrapper .panel-body {
  padding-bottom: 0;
}
