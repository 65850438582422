body.page-guild {

  .pane-page-title {
    display: none;
  }

  .pane-page-messages, .pane-page-tabs, .pane-page-breadcrumb, .pane-page-content, .pane-page-actions {
    background: #FFF2CC;
  }

  @media (max-width: 991px) {
    .radix-layouts-content, .radix-layouts-sidebar {
      width: 100%;
      float: none;
    }
  }

  @media (min-width: 1200px) {
    .radix-layouts-content {
      padding-right: 80px;
    }
  }

  .radix-layouts-content {
    h4 {
      margin-bottom: 30px;
    }
  }

  #intro {
    font: 46px/46px 'Allura', script;
    color: #002060;
    h1 {
      font-size: 52px;
      @mobile-only {
        font-size: 30px;
      }
    }
    h3 {
      font-size: 35px;
      margin: 9px 0;
    }
    padding: 40px 0;
    img {
      max-width: 800px;
    }
  }

  #buttons {
    a {
      display: block;
      text-align: center;
      transition: all 300ms;
      transform: scale(1, 1);
      background: #337ab7;
      padding: 24px;
      color: #FFF;
      font-size: 20px;
      border-radius: 10px;
      margin: 0 20px 30px;
      small {
        display: block;
        margin-top: 12px;
        font-size: 15px;
        line-height: 19px;
      }
      &:hover {
        transform: scale(1.06, 1.06) translateY(-10px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
        text-decoration: none;
      }
    }
  }
}
