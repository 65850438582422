.pane-panels-mini.pane-mentor-profile {
  .radix-layouts-sidebar {
    text-align: center;
  }

  .field-name-field-user-image .field-item {
    display: inline-block;
    padding-bottom: 12px;
    background: url(images/bg-shadow-sm.png) no-repeat center bottom;
  }
}

body.dashboard .pane-mentor-profile .pane-content {
  padding: 0;
}

body.dashboard .pane-mentor-profile > .pane-content > div {
  padding: 15px;
}
