// Pager.
.view .date-nav-wrapper {
  .date-nav {
    padding-bottom: 0;
    margin-bottom: 2em;
  }
  .pager {
    margin: .5em 0;
  }
  .date-prev, .date-next {
    position: relative;
    right: 0;
    background: none;
    padding: 0;
    border-radius: 0;
  }
}
