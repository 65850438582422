#pricing-individuals {
  padding-bottom: 50px;
  h3 {
    margin: 50px 0 40px;
    font: 30px/1.2 @serif;
    position: relative;
    &:after {
      width: 80px;
      content: " ";
      display: block;
      height: 2px;
      background: #927f18;
      bottom: -15px;
      left: calc(50% - 40px);
      position: absolute;
    }
  }
  background: #FFF;
  @media @tablet {
    background: url('/sites/default/files/bg-laptop.jpg') no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
  }
  padding-bottom: 50px;
  p.intro {
    background: rgba(255, 255, 255, 0.7);
    padding: 30px 40px;
    margin: 20px auto 45px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
  }
  a.button {
    background: #337ab7;
    display: block;
    width: 300px;
    margin: 18px auto 0;
    padding: 10px;
    color: #FFF;
    transition: all 300ms;
    &:hover {
      transform:translateX(6px);
      text-decoration: none;
      background: #457654
    }
  }
}
