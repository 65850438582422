// Main menu.
@main-menu-bg-color: @brand-primary;
@main-menu-font-family: @font-family-base;
@main-menu-sublinks-font-family: @main-menu-font-family;
@main-menu-font-size: 16px; // deprecated
@main-menu-font-size-xs: @main-menu-font-size;
@main-menu-font-size-sm: @main-menu-font-size;
@main-menu-font-size-md: @main-menu-font-size;
@main-menu-font-size-lg: @main-menu-font-size;
@main-menu-horizontal-centered: false;
@main-menu-uppercase: false;
@main-menu-italic: false;
@main-menu-text-shadow: 0 2px 4px rgba(0,0,0,0.5);
@main-menu-link-padding: 10px;
@main-menu-link-color: white;
@main-menu-link-border-color: false;
@main-menu-arrow-position: 'right';
@main-menu-arrow-right: '\e080';
@main-menu-arrow-down: '\e114';
@main-menu-arrow-color: fade(@main-menu-link-color, 50%);
@main-menu-arrow-font-size: .625em;
@main-menu-link-active-color: @main-menu-link-color;
@main-menu-link-active-bg-color: darken(@main-menu-bg-color, 5%);
@main-menu-link-hover-color: @main-menu-link-color;
@main-menu-link-hover-bg-color: darken(@main-menu-link-active-bg-color, 10%);
@main-menu-sublinks-bg-color: lighten(@main-menu-bg-color, 5%);
@main-menu-sublinks-text-shadow: @main-menu-text-shadow;
@main-menu-sublinks-box-shadow: false;
@main-menu-sublinks-font-size: @main-menu-font-size-xs;
@main-menu-mobile-toggle-link-color: @main-menu-link-color;
@main-menu-mobile-toggle-link-padding: @main-menu-link-padding;
@main-menu-mobile-toggle-bg-color: @main-menu-bg-color;
@main-menu-mobile-toggle-active-link-color: @main-menu-mobile-toggle-link-color;
@main-menu-mobile-toggle-active-bg-color: darken(@main-menu-mobile-toggle-bg-color, 5%);
@main-menu-mobile-bg-color: transparent;
@main-menu-mobile-link-color: @main-menu-link-color;
@main-menu-mobile-link-bg-color: @main-menu-bg-color;
@main-menu-mobile-link-border-color: darken(@main-menu-mobile-link-bg-color, 5%);
@main-menu-mobile-sublinks-bg-color: lighten(@main-menu-mobile-link-bg-color, 5%);
@main-menu-mobile-subsublinks-bg-color: lighten(@main-menu-mobile-link-bg-color, 10%);
@main-menu-mobile-subsubsublinks-bg-color: lighten(@main-menu-mobile-link-bg-color, 15%);

// Main menu.
.pane-superfish-1 .pane-content {
  .clearfix();
  background-color: @main-menu-bg-color;
  font-family: @main-menu-font-family;

  // Set responsive font sizes.
  font-size: @main-menu-font-size-xs;
  @media @tablet {
    font-size: @main-menu-font-size-sm;
  }
  @media @desktop {
    font-size: @main-menu-font-size-md;
  }
  @media @wide {
    font-size: @main-menu-font-size-lg;
  }

  // Mobile menu toggle.
  .sf-accordion-toggle {
    a {
      display: block;
      padding: @main-menu-mobile-toggle-link-padding;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      color: @main-menu-mobile-toggle-link-color;
      background-color: @main-menu-mobile-toggle-bg-color;
      & when not (@main-menu-text-shadow = false) {
        text-shadow: @main-menu-text-shadow;
      }
      &:hover, &.sf-expanded {
        color: @main-menu-mobile-toggle-active-link-color;
        background-color: @main-menu-mobile-toggle-active-bg-color;
      }
      &:before {
        .glyphicon();
        content: '\e236';
        margin-right: 5px;
      }
    }
  }

  // Mobile menu.
  .sf-accordion {
    background: @main-menu-mobile-bg-color;
    a {
      padding: 10px;
      color: @main-menu-mobile-link-color;
      background-color: @main-menu-mobile-link-bg-color;
      border-top: 1px solid @main-menu-mobile-link-border-color;
      text-decoration: none;
      &.menuparent:after {
        content: @main-menu-arrow-right;
        font-family: 'Glyphicons Halflings';
        margin-left: .5em;
        font-size: .625em;
        & when not (@main-menu-text-shadow = false) {
          text-shadow: none;
        }
      }
    }
    li.sf-depth-1 > a {
      & when not (@main-menu-text-shadow = false) {
        text-shadow: @main-menu-text-shadow;
      }
      & when (@main-menu-uppercase) {
        text-transform: uppercase;
      }
    }
    .sf-expanded > .menuparent:after {
      content: @main-menu-arrow-down;
    }
    .sf-clone-parent .menuparent:after {
      display: none;
    }
    ul a {
      padding-left: 20px;
      background-color: @main-menu-mobile-sublinks-bg-color;
      & when not (@main-menu-sublinks-font-family = @main-menu-font-family) {
        font-family: @main-menu-sublinks-font-family;
      }
      & when not (@main-menu-sublinks-text-shadow = false) {
        text-shadow: @main-menu-sublinks-text-shadow;
      }
    }
    ul ul a {
      padding-left: 30px;
      background-color: @main-menu-mobile-subsublinks-bg-color;
    }
    ul ul ul a {
      padding-left: 40px;
      background-color: @main-menu-mobile-subsubsublinks-bg-color;
    }
  }

  // Horizontal menu.
  .sf-horizontal {

    // Hide on mobile, to prevent flash before JS can hide it.
    @media @mobile-only {
      > li {
        display: none;
      }
    }

    // Links.
    a {
      padding: @main-menu-link-padding;
      color: @main-menu-link-color;
      transition: all 300ms;
      &.sf-depth-1 {
        & when not (@main-menu-text-shadow = false) {
          text-shadow: @main-menu-text-shadow;
        }
        & when (@main-menu-uppercase) {
          text-transform: uppercase;
        }
        & when (@main-menu-italic) {
          font-style: italic;
        }
        & when (@main-menu-arrow-position = 'bottom') {
          padding: (@main-menu-link-padding + (@main-menu-font-size-sm * .5));
          @media @desktop {
            padding: (@main-menu-link-padding + (@main-menu-font-size-md * .5));
          }
          @media @wide {
            padding: (@main-menu-link-padding + (@main-menu-font-size-lg * .5));
          }
        }
        & when (iscolor(@main-menu-link-border-color)) {
          border-left: 1px solid @main-menu-link-border-color;
        }
      }
      &.menuparent:after {
        content: @main-menu-arrow-right;
        font-family: 'Glyphicons Halflings';
        font-size: @main-menu-arrow-font-size;
        margin-left: .5em;
        color: @main-menu-arrow-color;
        & when not (@main-menu-text-shadow = false) {
          text-shadow: @main-menu-text-shadow;
        }
        & when (@main-menu-italic) {
          font-style: normal;
        }
      }
      &.sf-depth-1.menuparent:after {
        content: @main-menu-arrow-down;
        & when (@main-menu-arrow-position = 'bottom') {
          position: absolute;
          left: 50%;
          margin-left: -7px;
          bottom: ceil((.625 * @main-menu-font-size-sm / 4));
          @media @desktop {
            bottom: ceil((.625 * @main-menu-font-size-md / 4));
          }
          @media @wide {
            bottom: ceil((.625 * @main-menu-font-size-lg / 4));
          }
        }
      }
    }

    // Active and hover state for links.
    .sfHover > a, a.active, .active-trail a {
      color: @main-menu-link-active-color;
      background-color: @main-menu-link-active-bg-color;
      text-decoration: none;
    }

    // Make the actual hovered link slightly darker.
    a:hover {
      color: @main-menu-link-hover-color;
      background-color: @main-menu-link-hover-bg-color;
      text-decoration: none;
    }

    // Top level link border.
    li.last.sf-depth-1 a when (iscolor(@main-menu-link-border-color)) {
      border-right: 1px solid @main-menu-link-border-color;
    }

    // Sub menus.
    ul {
      background-color: @main-menu-sublinks-bg-color;
      line-height: 1.3em;
      font-size: @main-menu-sublinks-font-size;
      & when not (@main-menu-sublinks-font-family = @main-menu-font-family) {
        font-family: @main-menu-sublinks-font-family;
      }
      & when not (@main-menu-sublinks-box-shadow = false) {
        box-shadow: @main-menu-sublinks-box-shadow;
      }
      & when not (@main-menu-sublinks-text-shadow = false) {
        text-shadow: @main-menu-sublinks-text-shadow;
      }
      a {
        padding: 10px @main-menu-link-padding;
      }
      ul {
        background-color: @main-menu-mobile-subsublinks-bg-color;
        ul {
          background-color: @main-menu-mobile-subsubsublinks-bg-color;
        }
      }
    }

    // Calculate top position of sub menus.
    & > .sfHover > ul {
      & when (@main-menu-arrow-position = 'right') {
        top: (@main-menu-font-size-sm + (@main-menu-link-padding * 2));
        @media @desktop {
          top: (@main-menu-font-size-md + (@main-menu-link-padding * 2));
        }
        @media @wide {
          top: (@main-menu-font-size-lg + (@main-menu-link-padding * 2));
        }
      }
      & when (@main-menu-arrow-position = 'bottom') {
        top: ((@main-menu-font-size-sm * 2) + (@main-menu-link-padding * 2));
        @media @desktop {
          top: ((@main-menu-font-size-md * 2) + (@main-menu-link-padding * 2));
        }
        @media @wide {
          top: ((@main-menu-font-size-lg * 2) + (@main-menu-link-padding * 2));
        }
      }
    }

    // Centered menu.
    & when (@main-menu-horizontal-centered) {
      text-align: center;
      & > li {
        float: none;
        display: inline-block;
      }
      ul {
        text-align: left;
      }
    }

  }

}
