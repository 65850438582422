body.tracks-and-workshops {

  .pane-page-content {
    background: @tan;
  }

  h2.title {
    padding-bottom: 55px;
    background: url('images/accent.png') no-repeat center bottom;
    background-size: 175px 31px;
    font-family: @serif;
  }

  label {
    font-size: 18px;
    &:first-child {
      margin-right: 30px;
    }
  }

  .pane-page-content .form-type-select {
    text-align: center;
    margin: 50px 0 30px;
    label {
      margin-right: 20px !important;
      font: italic 300 18px/18px @sans;
    }
    .form-control {
      width: initial;
      display: inline-block;
      text-align: left;
    }
  }

  // Introductory Paragraph
  .pane-custom.pane-3 {
    margin-bottom: 0;
  }
  div.intro {
    background: #EDEDED;
    padding: 25px 40px;
    margin-top: 50px;
    .paper();
    h3 {
      font-family: @serif;
      margin-top: 0px;
    }
    p {
      font: 16px/24px @sans;
    }
  }

  // Hide everything. Panes will be shown via JavaScript in
  // tracks-and-workshops.js
  .tracks-listing,
  .alpha-listing {
    display: none;
  }

  // Spanish radio buttons
  .pane-custom.pane-2 {
    .text-left {
      text-align: left;
      padding-left: 20px;
      display: grid;
      grid-template-columns: 1.15fr .85fr;
      label {
        margin: 0;
        padding-left: 25px;
      }
    }
  }

  // FUS Tracks
  .view-fus-tracks {
    .views-field-field-track-description {
      font: 16px/22px @sans;
    }
    h3 {
      margin-top: 80px;
    }
    .image-title {
      position: relative;
      margin-top: 80px;
      margin-bottom: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
      .track-title {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        padding: 50px 20px 20px;
        margin-bottom: 0;
        width:100%;
        color: #FFF;
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 2) 100%);
      }
    }
    .handout, .view-alpha {
      float: left;
      a {
        margin: 12px 20px 0 0;
        display: inline-block;
        max-width: 330px;
        padding: 10px 20px;
        text-align: center;
        border-radius: 3px;
        transition: all 300ms;
        color: #FFF;
        .paper();
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
        padding-left: 50px;
        background:url(images/pdf.png) no-repeat 8px center @aqua;
        background-size: 35px 47px;
        &:hover, &:focus {
          text-decoration: none;
          opacity: 0.5;
          transform: translate(0, -5px);
        }
      }
    }
    .view-alpha a {
      background:url(images/list.png) no-repeat 8px center @aqua;
      background-size: 35px 35px;
    }
  }

  // Right Sidebar
  #sidebar-pricing {
    margin-top: 30px;
    top: 40px;
    max-width: 260px;
    font: 14px/1.35 @sans;
    @media @tablet-only {
      position: fixed;
      top: 290px;
    }
    @media @normal-only {
      max-width: 210px;
    }
    p {
      margin-bottom: 20px;
    }
  }

  // Alphabetical Listing of Workshops
  .alpha-listing {
    .panel, .panel-default, .panel-default > .panel-heading {
      color: #333333;
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 0;
      margin: 0;
      margin-bottom: 14px;
    }
    .panel-title a {
      font: 16px/18px @sans;
      padding-left: 23px;
      /* Right arrow */
      background: @right-arrow;
      background-size: 16px 16px;
      &.expanded {
        /* Down arrow */
        background: @down-arrow;
        background-size: 16px 16px;
      }
    }
    .panel-body {
      .callout();
      margin-bottom: 30px;
    }
    .workshop-description {
      margin-bottom: 12px;
    }
    .workshop-link {
      float: right;
      font: 15px/16px @sans;
    }
    ul.workshop-tracks {
      margin: 0;
      font: italic 15px/16px @sans;
    }
  }
}
