// Modal backdrop color.
div#modalBackdrop {
  background-color: black !important;
  opacity: .3 !important;
}

// Set a max-width on the modal.
.ctools-modal-dialog {
  max-width: 1260px;
}

// Force standard styling for modals.
#modalContent {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333;
  .modal-title {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    color: #333;
  }
}
