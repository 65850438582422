// Search results.
.search-result {
  .title {
    margin-bottom: 3px;
  }

  .search-info {
    .text-muted();
    .small();
    margin-bottom: 3px;
  }
}
