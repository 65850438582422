// Fix font-sizes for Bootstap classes which jQuery UI is mangling.
.ui-widget input.form-control,
.ui-widget button.btn {
  font-family: @font-family-sans-serif;
  font-size: @font-size-base;
}

// Fix overlay backgrounds. See it in action with the Linkit modal.
.ui-widget-overlay {
  background-repeat: repeat;
}

// Borders around autocomplete items. See it in action on an Autocomplete
// Deluxe field.
.ui-menu .ui-menu-item a {
  border: 1px solid #ccc;
}

// Put jQuery UI "front" elements just a bit higher, so they appear above the
// Ctools modal, CKEditor maximize plugin, etc.
.ui-front {
  z-index: 10001 !important;
}
