#hero {
  background-size: cover;
  background-position: top;
  margin-bottom: 0;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
  }
  > div > div {
    width: 40%;
  }
  @media (max-width: 991px) {
    > div {
      flex-direction: column;
    }
    > div > div {
      margin-top: 40px;
      width: 80%;
    }
  }

  .pane-content {
    text-align: center;
    color: #FFF;

    p {
      font: 34px/38px @serif;
      @media @mobile-only {
        font: 24px/28px @serif;
      }
    }

  }
}
