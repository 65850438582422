.i18n-es #pricing-mobile,
.i18n-es #pricing-desktop {
  .pane-content {
    .attr-col {
      ul li {
        height: 63px;
        @media @normal {
          height: 72px;
        }
        @media @wide {
          height: 80px;
        }
      }
    }

    .pt-rows li > span {
      height: 63px;
      @media @normal {
        height: 72px;
      }
      @media @wide {
        height: 80px;
      }
    }

    .pt-rows li > span {
      width: 33%;
      @media @normal {
        width: 25%;
      }
    }
  }
}