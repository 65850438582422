.pane-custom-course-prerequisites {
  h3 {
    font: 900 26px/1.1 'Open Sans';
  }

  h4 {
    font: 900 24px/1.1 'Open Sans';
    margin-top: 30px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 3px;
  }

  p {
    font-size: 14px;
    opacity: 0.8;
  }

  .complete {
    color: @green;
    font-size: 14px;
  }
}
