.pane-panels-mini.pane-mentor-dashboard,
.green-pane {

  font-size: 14px;

  > .pane-title {
    &:extend(.green-pane-title);
  }

  .pane-user-field-user-biography {
    margin-bottom: 12px;
  }

  .pane-mentor-profile {
    > .pane-content {
      &:extend(.green-light-bg);
      table {
        background-color: #FFF;
      }
    }
  }

  @media @tablet {
    .pane-user-field-user-image {
      float: left;
      margin-right: 20px;
      margin-bottom: 8px;
    }
  }

  @media (max-width: 767px) {
    .panel-pane.pane-user-field-user-image {
      margin-bottom: 0;
    }
  }

  .field-name-field-mentor-biography {
    margin-bottom: 20px;
    font-size: 14px;
  }

  // Learners you are mentoring.
  .pane-mentor-learners-panel-pane-1 {
    .pane-content {
      &:extend(.green-light-bg);
      table {
        background-color: #FFF;
      }
    }
  }

  // Mentor Learners view.
  .view-mentor-learners {
    .views-row {
      padding: 10px 0;
      text-align: center;
    }
    .views-field-field-user-image {
      img {
        border-radius: 50%;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      }
      margin-bottom: 10px;
    }
    .views-field-name {
      font-style: italic;
    }
    .views-field-name,
    .views-field-field-user-phone,
    .views-field-mail {
      margin-bottom: 5px;
    }
    .views-field-mail {
      margin-top: 8px;
    }
  }

  // Learner workshop submissions.
  .pane-mentor-learner-submissions-panel-pane-1 {
    .pane-content {
      &:extend(.green-light-bg);
    }
    table {
      background: #FFF;
      th {
        background: #888888 !important;
        border: 1px solid #ababab;
        color: #FFF !important;
        padding-left: 8px !important;
        border-bottom: 0 !important;
        font-weight: normal;
      }
      td {
        &:first-child {
          border-left: 1px solid #CCC;
        }
        &:last-child {
          border-right: 1px solid #CCC;
        }
        padding-left: 8px !important;
      }
      tr:last-child td {
        border-bottom: 1px solid #CCC;
      }
    }
  }

}
