.page-freecourses-user-edit,
.page-user-mooc-edit {
  #branding {
    display: none;
  }
}

.view-mooc-dashboard {
  &.view-display-id-block_available,
  &.view-display-id-block_registered,
  &.view-display-id-block_completed {
    .btn {
      padding: 3px 12px;
    }
  }

  &.view-display-id-block_registered,
  &.view-display-id-block_completed {
    .btn {
      margin-left: 10px;
    }
  }
}