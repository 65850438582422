// Undo what we did in bootstrap.less (the thing which prevents buttons with
// long text from breaking layouts).
.bootstrap-select .btn {
  white-space: nowrap;
}

// Add magnifying glass icon as background to search input element.
.bs-searchbox input {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAABGdBTUEAALGPC/xhBQAAAXFQTFRFAAAAAAAAgICAqqqqgICAkpKSn5+foqKiqqqqsbGxpKSkqqqqr6+vrq6uqqqqsbGxra2tr6+vqqqqrq6urKysrq6urKysrq6urKysra2tr6+vsbGxr6+vsLCwra2trq6usLCwrq6urq6usbGxr6+vrq6usbGxrq6usLCwr6+vsLCwrq6ur6+vsbGxsLCwsbGxsbGxsbGxsLCwr6+vsLCwsbGxsLCwsbGxsLCwr6+vsLCwsbGxsLCwsbGxr6+vsbGxsbGxsLCwsbGxsLCwsLCwr6+vsLCwsLCwsbGxsLCwsLCwsLCwsLCwsbGxsLCwsLCwsLCwsLCwsbGxsLCwsbGxsbGxsLCwsLCwsLCwsLCwsLCwsbGxsLCwsLCwsbGxsLCwsLCwsLCwsLCwsLCwsbGxsbGxsLCwsLCwsbGxsbGxsLCwsbGxsLCwsLCwsbGxsLCwsbGxsLCwsLCwsbGxsbGxsbGxsbGxsLCwsLCwsbGxsbGx4kLgtwAAAHp0Uk5TAAECAwQHCAsMDQ4PEBMVGiIjJCYoKSssLjIzNDY3ODk6PD9BRkhLTE1QUVJTVVdYYmVrbXFydHV4eXt8fn+DiY+Sk5ebnZ6foKGip6iqq6yur7O1tre4ubu8v8DGzM3Oz9PV2N3e4+Xn6Onr7O3u8PHy8/X3+Pv8/f59/GCaAAACuElEQVRYw+1W+1sSQRRdBEFFzdICJYMUxFJTfOAbH2mYD1Q08wmoqQUWYSixf30kd6ad2Zm9m/v1W/eX/b5zzzkzO3PvzCiKMNy+YP9guKulRnlAtI0mv6gQt+n5sPOv1HUjKZWL/FKHaXn9zFdVFHsvzOnfXKmSKMebcbk7oRpELoTpn31SDaMcM9YHvqlYvLMb6X8w3J8XB5tryZMb1mHdJp+/dvzvy70NVdjmjWa0DgvS9dP8f3bMxeT8uxqHiMRAs/5v3bpszyXNFr3i/aeEwmtRvmmPElKi7qin9ZOTFK3jPXUYFqRn6PjSoq/ZIJxrl75/aP2/km9zLd2NqC43QtfPqNBai8C60pUT6d9so2GpzpJxglziKUmMIScF+dNVLjFK6s+FdFuMzJTDk4AvY+36hEzVw+Lk/OtFD4xTYA4xaCPpvwbUYBGocwzqA/QCP7IiQN1m0CCgB7hBN1CPGLQf0E3coJ00FIMOArqGG3iAesagYUCTuIFfOIMuQE9wgz6g7jNoC6A3NtRgCqhxttNvAfaiBjvAnGThtLTPuXAWgMndUfMAZzCDASCWuLYn26D6EYND4B3zM8tDYtdY/5IMNM5nlkimx0hvJ2t194hPdRCDyyYDg2nCSuhzH0nug0OqD5YIySco0TJJrsheZc/pPb0lSq/Qe2ejVjw+1Rcfi/LNOeqQaRWs3zSdvzohnmGo/Of+na3j9y+teecEkCP7/vaLaabpHDhkH42dEoc4QztdjHS3e/x9UzsF3bNT4uBYV02GzMG2YNVBiRSN34m4gzcllxcnAnncoWb4WqLfqmxMpwkHxRUVvLfvEtX6N+Wg2IOrWa26dDxO+9ecw+8bZGhu+yh1ltqPT4aY88u0gzT+O/wDh3O7NYfPbYpixeHBenCwoL93sKSvOJxb01e6rvr5BXPRWka9fCqqAAAAAElFTkSuQmCC') no-repeat right 4px center;
  background-size: 16px 16px;
  padding-right: 26px;
}
