// Learner Track Progress
.learner-track-progress {
  margin: 30px 0;

  h3 {
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
    padding-bottom: 12px;
  }

  .top-wrapper {
    display: flex;
    justify-content: space-between;

    .learner-info {
      width: 500px;
    }

    .instructions {
      width: 490px;
    }
  }

  .user-photo {
    float: left;
    margin-right: 14px;
  }

  .user-name {
    padding-top: 12px;
    font-size: 1.25em;
    font-style: italic;
  }

  .track-wrapper {
    margin-top: 10px;

    .track-title,
    .course-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 1px solid #ccc;
      padding-bottom: 8px;

      h4 {
        font-weight: bold;
        font-size: 1.5em;
        margin-bottom: 0;
      }

      #track-status {
        cursor: pointer;
      }
    }

    .pointer {
      cursor: pointer;
    }

    .course-title {
      padding: 20px 0;
      border-bottom: 0;

      h5 {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    .in-progress span {
      color: #BD0FE1;
      font-style: italic;
    }

    .completed span {
      color: green;
      font-weight: bold;
      font-style: normal;
    }

    .course-wrapper {
      h5 {
        font-weight: bold;
        font-size: 1.25em;
      }

      .inner {
        margin-left: 14px;

        h6 {
          font-size: 0.85em;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 4px;
          display: inline-block;
          border-bottom: 1px solid #555;
        }

        .workshop-wrapper {
          margin-left: 14px;
          padding: 4px;

          &:last-child {
            margin-bottom: 16px;
          }

          display: flex;

          .name {
            width: 425px;
            padding-right: 30px;
          }

          .status {
            width: 130px;
            padding-right: 30px;
            text-align: center;
          }

          .mentor-status {
            padding-right: 30px;
          }

          .check {
            width: 50px;
          }

          .notes {
            width: 500px;
          }

          .form-control {
            height: 28px;
            padding: 6px 10px;
            font-size: 13px;
            line-height: 13px;
            margin-bottom: 4px;
            width: 100%;

            &::placeholder {
              color: rgba(0, 0, 0, 0.25);
              font-style: italic;
            }
          }
        }
      }

      margin-bottom: 20px;
    }
  }

  .mentor-switch-box > div,
  .mentor-switch-box > button {
    float: left;
    margin: 5px;
  }

  .mentor-switch-button > button {
    border: none;
    background: none;
    padding: 0;
    color: #337ab7;
    font-size: 0.9em;
  }

  .mentor-switch-button > button:hover {
    text-decoration: underline;
  }

  .mentor-switch-current {
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 1.1em;
  }
}