#mini-panel-diocese_crm_diocese_info {
  padding: 0 22px;
  font-size: 14px;

  a {
    word-wrap: break-word;
  }

  .panel-pane.diocese-and-region {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .view-diocesan-officials .views-row {
    &:extend(.clearfix all);
    margin-bottom: 16px;
    &.views-row-last {
      margin-bottom: 0;
    }

    .views-field-name {
      font-size: 18px;
      font-weight: bold;
      margin-top: 16px;
    }
  }

  .views-field-field-user-image {
    float: left;
    margin-right: 20px;
    img {
      border-radius: 50%;
    }
  }

  .general-demographics {
    line-height: 25px;
  }
}

.view-diocese-crm-list-of-notes {
  margin-top: 35px;

  .note-date {
    font-size: 12px;
    font-weight: bold;
    padding: 8px 20px;
    border-bottom: 1px solid #ccc;
    margin: -16px -20px 14px -20px;
  }
  .views-field-field-note-spoke-with {
    font-size: 12px;
    margin-bottom: 12px;
    .views-label {
      float: left;
      margin-right: 4px;
    }
  }
  .note-body {
    font-size: 14px;
    padding-bottom: 10px;
  }
  .meta-info {
    padding-top: 10px;
    border-top: 1px solid #ccc;
    margin-top: 10px;
    font-size: 14px;
  }
  .author {
    font-weight: bold;
    font-style: italic;
    font: 22px/22px 'Allura', script;
  }
  .views-row {
    padding: 16px 20px 10px;
    background: #FFF;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 35px;
  }
  .views-row-last {
    margin-bottom: 0;
  }
}

.pane-diocese-crm-list-of-notes-panel-pane-2 {
  .archived-notes-header {
    margin-top: 40px;
    margin-bottom: 18px;
    cursor: pointer;
    transition: all 300ms;
  }

  .view-content {
    .callout();
  }
}

// Internal Notes Form.
#custom-diocese-internal-notes-form {
  .form-control {
    background-color: transparent;
  }
  label {
      font-size: 22px;
      font-weight: normal;
      margin-bottom: 10px;
      text-align: center;
      display: block;
      font-style: italic;
  }
  .form-actions {
    text-align: right;
    button {
      transition: all 300ms;
      &:extend(.btn-default);
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
      span {
        display: none;
      }
    }
  }
  #field-diocese-internal-notes-add-more-wrapper .cke_top {
    background: #FDFDFA;
    border-bottom: 0;
  }

  #field-diocese-internal-notes-add-more-wrapper .cke_bottom {
    display: none;
  }

  #field-diocese-internal-notes-add-more-wrapper .cke_chrome {
    border: 0px;
    padding: 0;
    box-shadow: 0 0 3px rgba(0,0,0,.2);
  }
}
